<template>
  <div >
    <v-row justify="center" >
      <v-col md="8">
        <v-card class="shadow-0 r">
          <v-card-title>Description</v-card-title>
          <v-card-text v-if="description"
              v-html="description"
          ></v-card-text>

          <v-card-text v-else
          >
            <h4 class="text--primary">No description found!</h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row justify="center">
      <v-col md="8">
        <v-card class="shadow-0 rounded-5">
          <v-card-title>Packages</v-card-title>

          <div style="background-color: rgba(240, 245, 249, 1)" v-for="(dur , k) in durations" :key="k" class="rounded-5 mb-4">
            <v-card-text v-for="(pkg ,i) in dur.packages" :key="i" >
              <div class="d-flex justify-space-between">
                <p class="text--black text-capitalize font-bold">
                  Package :
                  {{ pkg.pricing[0].name }}

                </p>
                <p class="text--black text-capitalize font-bold">
                  Type :  {{
                    pkg.membership_type == "I"
                        ? "Individual"
                        : pkg.membership_type == "C"
                            ? "Couple"
                            : "Group"
                  }}
                </p>
                <p class="text--black text-capitalize font-bold">
                  {{ dur.year > 0 ? dur.year + " Year(s)" : "" }}
                  {{ dur.month > 0 ? dur.month + " Month(s)" : "" }}
                  {{ dur.day > 0 ? dur.day + " Day(s)" : "" }}
                </p>
              </div>
              <v-simple-table class="table p-4 table-bordered overflow-y-auto" style="background-color: #FFFFFF; border-radius: 12px">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <td class="">Payment Plan</td>
                    <td class="">Price</td>
                    <td class=""  v-if="checkReadPermission($modules.memberships.members.slug)">Members</td>
                    <td  v-if="checkReadPermission($modules.memberships.sales.slug)">Total Sales</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="pricing in pkg.pricing"
                      :key="`pak_${pkg.id}_${pricing.id}`"
                  >
                    <td>
                      {{ pricing.installment.name }}
                    </td>
                    <td>{{ Number(pricing.total_price) | toCurrency }}</td>
                    <td v-if="checkReadPermission($modules.memberships.members.slug)">{{ pricing.members_count }}</td>
                    <td v-if="checkReadPermission($modules.memberships.sales.slug)">{{ Number(pricing.sales) | toCurrency }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </div>

        </v-card>
      </v-col>
    </v-row>




    <v-dialog v-model="membershipDialoge" scrollable width="750px">
      <v-card>
        <v-card-title class="headline"
          >Membership Registration
          <v-spacer></v-spacer>
          <div class="pitch">
            {{
              discount_applied &&
              discounted_price != undefined &&
              member.price != null
                ? "Price: " + currencyCode + discounted_price
                : member.price != undefined
                ? "Price:  " +
                  currencyCode +
                  (parseFloat(member.price) + parseFloat(member.tax))
                : "Price:" + currencyCode + "0"
            }}

            <span
              class="text-decoration-line-through"
              v-if="discount_applied && member.price != null"
            >
              {{
                currencyCode +
                  (parseFloat(member.price) + parseFloat(member.tax))
              }}</span
            >
          </div>
          <v-spacer></v-spacer>
          <div
            v-if="promotions.length > 0 && !paidFlag"
            style="margin-bottom: -30px"
          >
            <v-autocomplete
              v-if="promotions.length > 0 && member.price != null"
              :items="[{ name: 'None', promotion_code: null }, ...promotions]"
              item-text="name"
              height="50"
              item-value="promotion_code"
              v-model="member.promotion_code"
              background-color="rgb(206, 168, 0)"
              outlined
              :readonly="renewOrEdit"
              @change="verifyBenefit('promotion')"
              label="Promotions"
            >
            </v-autocomplete>
          </div>
        </v-card-title>
        <v-card-text class="form_bg">
          <v-container>
            <v-form ref="form" v-model="valid">
              <div class="titles" style="margin-bottom: -10px">
                Package Details
              </div>
              <v-divider class="mb-4"></v-divider>
              <v-row>
                <v-col cols="6">
                  <v-select
                    label="Packages"
                    :items="packages"
                    return-object
                    item-text="name"
                    item-value="id"
                    v-model="member.package"
                    @change="checkPackageHasPricing()"
                    outlined
                    :rules="[(v) => !!v || 'Package is required']"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    :disabled="editFlag"
                  >
                    <template slot="item" slot-scope="data">
                      <template v-if="!(typeof data.item == 'object')">
                        <v-list-item-content style="text-align: center">
                          {{ data.item }}
                        </v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ data.item.type }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Plan"
                    :items="plans"
                    item-text="name"
                    item-value="id"
                    v-model="member.plan"
                    @change="memberPackagePrice()"
                    outlined
                    :rules="[(v) => !!v || 'Plan is required']"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    :disabled="editFlag"
                  >
                  </v-select>
                </v-col>
                <!-- <v-col cols="6">
                  <v-text-field
                    label="Price"
                    readonly
                    :prefix="currencyCode"
                    :value="member.price"
                    outlined
                    background-color="#fff"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="6" md="6">
                  <date-field
                    v-model="member.start_date"
                    label="Start Date"
                    @change="setEndDate('member')"
                    :rules="[(v) => !!v || 'Package is required']"
                    :backFill="
                      checkBackfillPermission(
                        $modules.memberships.management.slug
                      )
                    "
                  ></date-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <date-field
                    :disabled="member.package == null"
                    :min="member.start_date ? member.start_date : today"
                    label="End Date"
                    v-model="member.end_date"
                    :rules="[(v) => !!v || 'Package is required']"
                    @input="menu2 = false"
                    :backFill="
                      checkBackfillPermission(
                        $modules.memberships.management.slug
                      )
                    "
                  ></date-field>
                </v-col>
                <v-col sm="6">
                  <v-select
                    label="Tag"
                    v-model="member.tag"
                    :items="membershipTags"
                    item-text="name"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                  ></v-select>
                </v-col>

                <v-col
                  sm="6"
                  v-if="checkReadPermission($modules.salesTeam.management.slug)"
                >
                  <v-select
                    v-model="member.sales_team_id"
                    label="Sales Team"
                    :items="salesTeams"
                    item-text="name"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                  ></v-select>
                </v-col>
                <!-- <v-col cols="6">
                  <v-file-input
                    label="Contract File"
                    v-model="member.contract"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    outlined
                    background-color="#fff"
                  ></v-file-input>
                  <v-chip
                    v-if="member.contract_path != null"
                    target="_blank"
                    :href="s3BucketURL + member.contract_path"
                  >
                    <v-icon left>mdi-file-download-outline</v-icon>Uploaded
                    Contract
                  </v-chip>
                </v-col> -->
              </v-row>
              <div
                v-if="documentFields.length"
                class="titles"
                style="margin-bottom: -10px"
              >
                Documents
              </div>
              <v-divider v-if="documentFields.length" class="mb-4"></v-divider>
              <v-card
                :style="cardStyle"
                class="pt-3 pl-2 pr-2 mt-8"
                elevation="0"
                v-if="documentFields.length"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    v-for="(documentField, index) in documentFields"
                    :key="index"
                  >
                    <v-file-input
                      :label="documentField.name"
                      v-model="documentField.file"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon
                      outlined
                      background-color="#fff"
                      :rules="documentUploadRule(documentField)"
                    >
                      <template v-slot:prepend-inner>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              color="cyan"
                              v-if="
                                documentField.document_id &&
                                  documentField.file_path
                              "
                              @click="openFile(documentField.file_path)"
                              v-on="on"
                            >
                              mdi-download-box
                            </v-icon>
                            <v-icon v-else v-on="on"> mdi-paperclip </v-icon>
                          </template>
                          <span
                            v-if="
                              documentField.document_id &&
                                documentField.file_path
                            "
                            >Download uploaded file</span
                          >
                          <span v-else>Upload Document</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:label>
                        <span v-if="!documentField.isEdit">
                          {{ documentField.name }}
                        </span>
                        <span
                          v-if="
                            documentField.isEdit == true &&
                              documentField.file == null &&
                              documentField.original_file_name
                          "
                          class="font-weight-bold"
                        >
                          <span
                            style="width: 70%; display: inline-block"
                            class="text-truncate"
                            >{{ documentField.original_file_name }}</span
                          >
                          <span
                            style="width: 20%; display: inline-block"
                            class="text-truncate"
                            >.{{
                              documentField.original_file_name.split(".")[
                                documentField.original_file_name.split(".")
                                  .length - 1
                              ]
                            }}</span
                          >
                        </span>
                      </template>
                      <template
                        v-slot:append
                        v-if="documentField.uploaded_file_path"
                      >
                        <v-btn
                          @click="openFile(documentField.uploaded_file_path)"
                          class="text-truncate"
                          depressed
                          color="primary"
                          text
                          style="transform: translate(0, -22%)"
                        >
                          Download {{ documentField.name }}
                        </v-btn>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-card>

              <v-row no-gutters>
                <v-col md="9">
                  <div class="titles d-flex justify-space-between mt-5">
                    <div>Member(s) Details</div>
                  </div>
                </v-col>
                <!-- Uncomment to enable emirates ID reader -->
                <!-- <v-col md="2" class="text-right" v-if="!member.order_id">
                  <card-data-button
                    class="mt-2"
                    label="Emirates ID"
                    @data="
                      (data) => {
                        setCardData(data);
                      }
                    "
                  ></card-data-button>
                </v-col> -->
              </v-row>
              <v-divider class="mb-4"></v-divider>
              <v-card
                :style="cardStyle"
                class="pt-3 pl-2 pr-2 mt-8"
                elevation="0"
                v-for="(customer, index) in member.customers"
                :key="index"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      fab
                      x-small
                      absolute
                      top
                      right
                      dark
                      @click="deleteCustomer(index)"
                      v-if="member.customers.length > 1 && !customer.member_id"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
                <v-card-text>
                  <h1
                    v-if="member.customers.length > 1"
                    class="text-center pa-4"
                  >
                    Member {{ index + 1 }}
                  </h1>
                  <v-row no-gutters justify="center">
                    <v-col md="6">
                      <image-upload
                        @upload="
                          (data) => {
                            customer.image = data;
                          }
                        "
                        @remove="
                          () => {
                            customer.image = null;
                          }
                        "
                        :image_path="customer.image_path"
                        :height="240"
                        defaultImage="user"
                      ></image-upload>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      md="3"
                      class="text-right d-flex"
                      v-if="!member.order_id"
                    >
                      <card-reader-button
                        class="mt-5"
                        label="Samsotech Reader"
                        docType="chip"
                        @data="
                          (data) => {
                            setCardData(data, index);
                          }
                        "
                      ></card-reader-button>
                      <card-data-button
                        class="mt-5"
                        label="HID Omnikey"
                        @data="
                          (data) => {
                            setCardData(data, index);
                          }
                        "
                      ></card-data-button>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col md="3">
                      <v-switch
                        style="float: right"
                        v-model="customer.opt_marketing"
                        label="Opt In Marketing"
                      ></v-switch>
                    </v-col>
                    <v-col md="3">
                      <v-switch
                        true-value="1"
                        false-value="0"
                        label="Primary Member"
                        v-model="customer.is_primary"
                      ></v-switch
                    ></v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-mobile-search
                        v-model="customer.search"
                        :selected="customer.mobile"
                        @updateCustomer="setCustomerData($event, index)"
                        ref="mobile"
                      ></v-mobile-search>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-name-search
                        :selected="customer.name"
                        :mobile="customer.mobile"
                        :email="customer.email"
                        v-model="customer.nameSearch"
                        @updateCustomer="setCustomerData($event, index)"
                        ref="name"
                      ></v-name-search>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="customer.email"
                        :label="`Email${field.email.is_required ? '*' : ''}`"
                        outlined
                        background-color="#fff"
                        :readonly="existingUser || customer.customer_id != null"
                        required
                        :rules="emailRule"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6" v-if="field.dob.is_visible">
                      <date-of-birth
                        :placeholder="
                          `Date of Birth${field.dob.is_required ? '*' : ''}`
                        "
                        :label="
                          `Date of Birth${field.dob.is_required ? '*' : ''}`
                        "
                        v-model="customer.dob"
                        :rules="dobRule()"
                      >
                      </date-of-birth>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="field.nationality.is_visible"
                    >
                      <v-autocomplete
                        v-model="customer.country_id"
                        :items="countries"
                        :hint="
                          `Nationality${
                            field.nationality.is_required ? '*' : ''
                          }`
                        "
                        :label="
                          `Nationality${
                            field.nationality.is_required ? '*' : ''
                          }`
                        "
                        :rules="nationalityRule"
                        item-value="id"
                        item-text="name"
                        outlined
                        background-color="#fff"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="field.gender.is_visible"
                    >
                      <v-select
                        v-model="customer.gender"
                        :items="['Male', 'Female']"
                        label="Gender"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        :rules="genderRule"
                        required
                      ></v-select>
                    </v-col>
                    <v-col sm="6" md="6" v-if="field.idProof.is_visible">
                      <v-select
                        v-model="customer.id_proof_type_id"
                        :hint="`ID Type${field.idProof.is_required ? '*' : ''}`"
                        :label="
                          `ID Type${field.idProof.is_required ? '*' : ''}`
                        "
                        :rules="idTypeRule"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        item-value="id"
                        item-text="name"
                        :items="idProofTypes"
                        @change="changeIdProofTypeId($event, index)"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" v-if="field.idProof.is_visible">
                      <v-file-input
                        :placeholder="
                          `${customer.id_proof_path ? 'Change' : 'Select'}${
                            field.idProof.is_required ? '*' : ''
                          }`
                        "
                        :label="
                          `${
                            customer.id_proof_path ? 'Download' : 'ID Proof'
                          } ${field.idProof.is_required ? '*' : ''}`
                        "
                        :rules="idProofRule"
                        v-model="customer.id_proof"
                        outlined
                        background-color="#fff"
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon
                      >
                        <template v-slot:append>
                          <v-chip
                            class="text-truncate"
                            v-if="
                              !customer.id_proof &&
                                customer.id_proof_path != null
                            "
                            target="_blank"
                            :href="s3BucketURL + customer.id_proof_path"
                            style="width: 97%; transform: translate(0, -18%)"
                          >
                            <v-icon left>mdi-file-download-outline</v-icon>
                            {{ customer.id_proof_file_name }}
                          </v-chip>
                        </template>
                      </v-file-input>
                      <!-- <v-chip
                        v-if="customer.id_proof_path != null"
                        target="_blank"
                        :href="s3BucketURL + customer.id_proof_path"
                      >
                        <v-icon left>mdi-file-download-outline</v-icon>
                        {{ customer.id_proof_file_name }}
                      </v-chip> -->
                    </v-col>
                    <v-col sm="6" md="6" v-if="field.idProof.is_visible">
                      <v-text-field
                        v-model="customer.id_proof_number"
                        :hint="
                          `ID Number${field.idProof.is_required ? '*' : ''}`
                        "
                        :label="
                          `ID Number${field.idProof.is_required ? '*' : ''}`
                        "
                        :rules="idTypeRule"
                        outlined
                        background-color="#fff"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="6" md="6" v-if="field.tag.is_visible">
                      <v-select
                        :items="allTags"
                        :placeholder="`Tags${field.tag.is_required ? '*' : ''}`"
                        :label="`Tags${field.tag.is_required ? '*' : ''}`"
                        outlined
                        item-value="id"
                        item-text="name"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="customer.customer_tag"
                        :rules="tagRule"
                        background-color="#fff"
                        return-object
                        multiple
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="6">
                      <v-text-field
                        label="Card Number"
                        v-model="customer.card_number"
                        outlined
                        background-color="#fff"
                      ></v-text-field>
                    </v-col> -->
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- member.package != null && (member.package.members_limit > member.customers.length || member.package.additional_price != null) -->

              <div class="add_btn mt-8" v-if="allowAdditionalMember">
                <v-btn color="teal-color" small dark @click="addNewCustomer">
                  Member
                  <v-icon small right>mdi-plus-circle</v-icon>
                </v-btn>
              </div>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 white--text blue-color"
            @click="
              (membershipDialoge = false),
                (editFlag = false),
                (renewOrEdit = false)
            "
            >Close</v-btn
          >
          <v-btn
            class="ma-2 white--text teal-color"
            @click="addOrUpdateMember"
            v-if="member.operation == 'add' || member.operation == 'renew'"
            >Save</v-btn
          >
          <v-btn
            v-else
            class="ma-2 white--text teal-color"
            @click="addOrUpdateMember"
            >Update Membership</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="historyDialoge" scrollable width="900">
      <v-card>
        <v-card-title class="headline">Membership History</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <table class="history_table">
                <thead>
                  <th>Timestamp</th>
                  <th>Package</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Price</th>
                  <th>Receipt</th>
                </thead>

                <tr
                  v-for="(history, index) in historyData"
                  :key="index"
                  class="text-center"
                >
                  <td>{{ history.timestamp | timeStamp }}</td>
                  <td>{{ history.package }}</td>
                  <td>{{ history.start_date | dateformat }}</td>
                  <td>{{ history.end_date | dateformat }}</td>
                  <td>
                    {{
                      history.status_id == 2
                        ? "InActive"
                        : history.status_id == 1
                        ? "Active"
                        : history.status_id == 5
                        ? "Unpaid"
                        : history.status_id == 13
                        ? "Cancelled"
                        : "Expired"
                    }}
                  </td>
                  <td>{{ Number(history.price) | toCurrency }}</td>
                  <td>
                    <v-btn small @click="showReceipt(history.order_id)">{{
                      history.status_id == 5 ? "Pay" : "Receipt"
                    }}</v-btn>
                  </td>
                </tr>
              </table>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="historyDialoge = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="displayNumberModal" scrollable width="20%">
      <v-card>
        <v-card-title class="headline">Display Number</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="mt-4">
                <v-text-field
                  v-model="displayNumber"
                  :label="`Display Number*`"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Display Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="displayNumberModal = false"
            >Close</v-btn
          >
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="saveDisplayNumber()"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="endDateModal" scrollable width="20%">
      <v-card>
        <v-card-title class="headline">End date</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="mt-4">
                <date-field
                  v-model="endDate"
                  label="End Date"
                  :rules="[(v) => !!v || 'End Date is required']"
                ></date-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="endDateModal = false"
            >Close</v-btn
          >
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="saveEndDate()"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showMemberShipCard" scrollable width="800">
      <FlipCard>
        <template slot="front">
          <v-card
            class="pa-5 member-card"
            :style="{ backgroundImage: 'url(' + membershipCardImage + ')' }"
          >
            <!-- <v-card
              class="mx-auto"
              style="border-radius: 29px; opacity: 0.96"
              max-width="800"
            > -->
            <!-- align="center" justify="center" -->
            <v-container fill-height fluid>
              <v-row>
                <v-col md="6" style="text-align: center" class="pa-13 pb-12">
                  <v-avatar
                    color="primary"
                    size="170"
                    style="text-align: center"
                  >
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="
                        selectedMemberDetails
                          ? s3BucketURL + selectedMemberDetails.profile_image
                          : null
                      "
                    ></v-img
                  ></v-avatar>
                </v-col>
                <v-col md="1"></v-col>

                <v-col md="5" class="pt-0 pr-0">
                  <v-img
                    width="100"
                    alt=""
                    style="margin-left: auto"
                    :src="
                      selectedMemberDetails
                        ? s3BucketURL + this.$store.getters.venueInfo.logo
                        : null
                    "
                  ></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2">
                  <span> NAME :</span>
                </v-col>
                <v-col md="5">
                  <span>
                    {{
                      selectedMemberDetails
                        ? selectedMemberDetails.first_name
                        : null
                    }}
                    {{
                      selectedMemberDetails
                        ? selectedMemberDetails.last_name
                        : null
                    }}
                  </span>
                </v-col>
                <v-col md="2" class="pl-0 pr-0">
                  <span> MEMBERSHIP # :</span>
                </v-col>
                <v-col md="3">
                  <span>
                    {{
                      selectedMemberDetails
                        ? selectedMemberDetails.display_card_number
                        : null
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2">
                  <span> VALIDITY :</span>
                </v-col>
                <v-col md="5">
                  <span>
                    {{
                      selectedMemberDetails
                        ? selectedMemberDetails.start_date
                        : null | dayFormat
                    }}
                    TO
                    {{
                      selectedMemberDetails
                        ? selectedMemberDetails.end_date
                        : null | dayFormat
                    }}
                  </span>
                </v-col>
                <v-col md="2" class="pl-0">
                  <span> TYPE :</span>
                </v-col>
                <v-col md="3">
                  <span>
                    {{ membershipName ? membershipName : null }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
            <!-- </v-card> -->
          </v-card>
        </template>
        <template slot="back">
          <v-card
            class="pa-5 member-card"
            style="height: 100%"
            :style="{ backgroundImage: 'url(' + membershipCardImage + ')' }"
          >
            <!-- <v-card
              class="mx-auto"
              style="border-radius: 29px; height: 100%"
              dark
              max-width="700"
            > -->
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col style="text-align: center" class="pa-15">
                  <v-img
                    width="150"
                    alt=""
                    style="margin-right: auto; margin-left: auto"
                    :src="
                      selectedMemberDetails
                        ? s3BucketURL + this.$store.getters.venueInfo.logo
                        : null
                    "
                  ></v-img>
                </v-col>
              </v-row>
            </v-container>
            <!-- </v-card> -->
          </v-card>
        </template>
      </FlipCard>
    </v-dialog>

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>

    <order-details
      :id="orderId"
      :ids="orderIds"
      :invId="invId"
      :invIds="invIds"
      @close="closeMainComponent"
      @paymentDone="getMembersList(), $emit('reload')"
    ></order-details>
    <customer-model
      v-bind="userModel"
      @confirm="getFilterData"
      @close="userModel.userID = null"
      @updated="search()"
      :isPermissionCheck="
        checkWritePermission($modules.memberships.management.slug)
      "
    />
    <MembershipFreezePopup
      v-if="freezeProduct"
      :mfdialog="mfdialog"
      :freezeData="freezeData"
      :freezeProduct="freezeProduct"
      @close="closeMfDialoag"
      @saveMfData="saveMfData"
    />
    <MembershipUpgrade
      :upgradeModal="upgradeModal"
      :id="memberId"
      @close="closeUpgradeModal"
    />
  </div>
</template>

<script>
import moment from "moment";
import OrderDetails from "@/components/Order/OrderDetails.vue";
import CustomerModel from "../../Clients/Customer/CustomerModel.vue";
import bookingFields from "@/mixins/bookingFieldValidation";
import FlipCard from "./MembershipCard.vue";
import MembershipFreezePopup from "@/components/Membership/MembershipFreezePopup.vue";
import MembershipUpgrade from "@/components/Membership/MembershipUpgrade.vue";
export default {
  name: "MembershipDetails",
  props: {
    membershipId: { type: Number, default: 0 },
    totalSales: { type: Number, default: 0 },
    totalMembers: { type: Number, default: 0 },
    membershipName: { type: String, default: "" },
    description: { type: String, default: "" },
    durations: { type: Array, default: () => {} },
    packages: { type: Array, default: () => {} },
    //membershipTagsList: {},
    membershipCardImage: {
      type: String,
      default: require("@/assets/images/default_images/membership_card.png"),
    },
  },
  components: {
    OrderDetails,
    CustomerModel,
    FlipCard,
    MembershipFreezePopup,
    MembershipUpgrade,
  },
  data() {
    return {
      upgradeModal: false,
      memberId: null,
      disableStatus: ["Expired", "Cancelled", "Deleted", "Refund", "Void"],
      endDate: null,
      endDateModal: false,
      displayNumberModal: false,
      displayNumber: null,
      editingMember: null,
      renewOrEdit: false,
      orderIds: null,
      invIds: null,
      invId: null,
      perPage: 10,
      historyDialoge: false,
      plans: [],
      member: {
        package: null,
        promotion_code: null,
        plan: null,
        customers: [
          {
            first_name: null,
            last_name: null,
            email: null,
            gender: null,
            country_id: null,
            dob: null,
            id_proof: null,
            id_proof_type_id: null,
            is_primary: 1,
            customer_tag: null,
          },
        ],
      },
      editFlag: false,
      packageSelected: {},
      discounted_price: 0,
      discount_applied: false,
      promotion: false,
      paidFlag: false,
      package: [],
      venue_service_id: null,
      searchParams: {},
      currentPage: 0,
      pageSize: 10,
      items: [],
      names: [],
      tags: [],
      tag: null,
      name: null,
      country_id: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      ageMenu: false,
      totalPages: 1,
      valid: false,
      membershipDialoge: false,
      page: 1,
      date: null,
      menu: false,
      ptDialoge: false,
      membersList: [],
      existingUser: false,
      userModel: { userID: null, type: "details" },
      historyData: [],
      orderId: null,
      today: moment().format("YYYY-MM-DD"),
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      pay_dialog: false,
      product: [],
      productType: "Membership",
      orderBy: "start_date",
      orderDir: "DESC",
      isEmiratesIdCheck: false,
      showMemberShipCard: false,
      selectedMemberDetails: null,
      isEnableApprovalProcess: false,
      mfdialog: false,
      freezeData: null,
      freezeProduct: null,
    };
  },
  mixins: [bookingFields],
  mounted() {
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags", "normal");
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
    if (!this.$store.getters.configurationStatus("Membership")) {
      this.$store.dispatch("loadConfigurations", "Membership").then((res) => {
        this.setDocumentFields();
        if (res.data.data && res.data.data.membership_configurations) {
          if (res.data.data.membership_freeze_product) {
            this.freezeProduct = res.data.data.membership_freeze_product;
          }
          this.isEnableApprovalProcess = Boolean(
            res.data.data.membership_configurations.is_enable_approval_process
          );
        }
        if (res.data.data && res.data.data.membership_card_configuration) {
          this.$emit(
            "changeImage",
            this.s3BucketURL +
              res.data.data.membership_card_configuration.image_path
          );
          // this.membershipCardImage =
          // this.s3BucketURL +
          //res.data.data.membership_card_configuration.image_path;

          this.$nextTick(() => {
            this.$forceUpdate;
          });
        }
        this.setFieldConfigurations();
      });
    } else {
      this.$emit(
        "changeImage",
        this.s3BucketURL +
          this.$store.getters.getMembershipCardByProduct("Membership")
            .image_path
      );
      // this.membershipCardImage =
      // this.s3BucketURL +
      //this.$store.getters.getMembershipCardByProduct("Membership").image_path;
      this.$nextTick(() => {
        this.$forceUpdate;
      });
      this.$nextTick(() => {
        this.isEnableApprovalProcess = Boolean(
          this.$store.getters.getMembershipConfigByProduct("Membership")
            .is_enable_approval_process
        );
        this.$forceUpdate;
      });
    }
    if (!this.$store.getters.getMembershipTagsByProduct) {
      this.$store.dispatch("loadConfigurations");
    }
    this.checkPermission = this.checkExportPermission(
      this.$modules.salesTeam.dashboard.slug
    );
    if (this.checkPermission) {
      this.$store.dispatch("loadSalesTeams", "Membership");
    }
    this.setFieldConfigurations();
    this.setDocumentFields();
    if (!this.freezeProduct) {
      this.getMembeshipFreezeProduct();
    }
  },

  computed: {
    allTags() {
      return this.$store.getters.getTags.data;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
    promotions() {
      return this.$store.getters.getPromotions.data;
    },
    membershipTags() {
      return this.$store.getters.getMembershipTagsByProduct;
    },
    salesTeams() {
      return this.$store.getters.getSalesTeams.data;
    },
    allowAdditionalMember() {
      // member.package != null && (member.package.members_limit > member.customers.length || member.package.additional_price != null)

      if (this.member.package != null && this.member.status != 4) {
        if (
          this.member.package.members_limit > this.member.customers.length ||
          this.member.package.additional_price != null
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.member.package != null && this.member.status == 4) {
        if (this.member.package.members_limit > this.member.customers.length) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
  },

  watch: {
    membershipDialoge(val) {
      if (val == false) {
        this.setDocumentFields();
      }
    },

    membershipId(val) {
      if (val != null) {
        this.getMembersList();
      }
    },

    page() {
      this.getMembersList();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
    tag(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("tag", val);
    },
  },

  methods: {
    closeMainComponent() {
      this.orderId = null;
      this.orderIds = null;
      this.invIds = null;
      this.invId = null;
    },
    getExpiryDate(member) {
      this.showLoader("Fetching details");

      this.$http
        .get(`venues/memberships/members/expiry-date/${member.member_id}`)
        .then((response) => {
          this.hideLoader();
          if (
            response.status == 200 &&
            response.data.status == true &&
            response.data.data !== ""
          ) {
            this.endDateModal = true;
            this.editingMember = member.member_id;
            this.endDate = response.data.data;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    saveEndDate() {
      let data = {
        end_date: this.endDate,
      };
      this.$http
        .post(
          `venues/memberships/members/expiry-date/${this.editingMember}`,
          data
        )
        .then((response) => {
          this.hideLoader();
          if (
            response.status == 200 &&
            response.data.status == true &&
            response.data.data !== ""
          ) {
            this.endDateModal = false;
            this.editingMember = null;
            this.endDate = null;
            this.$forceUpdate();
            this.getMembersList();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    getDisplayNumber(member) {
      this.showLoader("Fetching details");

      this.$http
        .get(
          `venues/memberships/members/get-display-number/${member.member_id}`
        )
        .then((response) => {
          this.hideLoader();
          if (
            response.status == 200 &&
            response.data.status == true &&
            response.data.data !== ""
          ) {
            this.displayNumberModal = true;
            this.editingMember = member.member_id;
            this.displayNumber = response.data.data;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    saveDisplayNumber() {
      let data = {
        member_id: this.editingMember,
        displayNumber: this.displayNumber,
      };
      this.$http
        .post(`venues/memberships/members/update-display-number`, data)
        .then((response) => {
          this.hideLoader();
          if (
            response.status == 200 &&
            response.data.status == true &&
            response.data.data !== ""
          ) {
            this.displayNumberModal = false;
            this.editingMember = null;
            this.displayNumber = null;
            this.$forceUpdate();
            this.getMembersList();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    getOrderDetails(data1) {
      this.showLoader("Fetching details");
      if (data1.invoice_generated && data1.invoice_id) {
        this.$http
          .get(
            `venues/memberships/members/get-parent-invoice/${data1.invoice_id}`
          )
          .then((response) => {
            this.hideLoader();
            if (
              response.status == 200 &&
              response.data.status == true &&
              response.data.data !== ""
            ) {
              let data = response.data.data;
              this.invIds = [data1.invoice_id];
              data.split(",").forEach((id) => {
                this.invIds.push(Number(id));
              });
              this.$forceUpdate();
            } else {
              this.invId = data1.invoice_id;
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      } else {
        this.$http
          .get(`venues/memberships/members/get-parent-order/${data1.order_id}`)
          .then((response) => {
            this.hideLoader();
            if (
              response.status == 200 &&
              response.data.status == true &&
              response.data.data !== ""
            ) {
              let data = response.data.data;
              this.orderIds = [data1.order_id];
              data.split(",").forEach((id) => {
                this.orderIds.push(Number(id));
              });
              this.$forceUpdate();
            } else {
              this.orderId = data1.order_id;
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      }
    },
    calculateClass(index) {
      let endClass = "md-table-row member-all";

      if (index == 0) {
        endClass += " member-parent";
      }
      if (
        index > 0 &&
        index <= this.membersList.length - 2 &&
        this.membersList[index].order_id == this.membersList[index + 1].order_id
      ) {
        endClass += " member-parent";
      }
      if (
        index > 0 &&
        index <= this.membersList.length - 1 &&
        this.membersList[index].order_id == this.membersList[index - 1].order_id
      ) {
        endClass += " member-child";
      }
      return endClass;
    },
    setCardData(data, index = null) {
      this.setCustomerData(data, index);
      // if (!data.customer_id) {
      //   this.$set(this.member.customers[0], "customer_id", null);
      // }

      // if (!data.name && data.first_name) {
      //   this.$set(this.member.customers[0], "name", data.first_name);
      // }

      // if (!data.customer_id && this.member.customers[0].name != data.name) {
      //   this.$set(this.member.customers[0], "mobile", null);
      //   this.member.customers[0].search = null;
      //   this.member.customers[0].nameSearch = null;
      //   this.$set(this.member.customers[0], "email", null);
      //   this.$set(this.member.customers[0], "gender", null);
      //   this.$set(this.member.customers[0], "name", null);
      //   this.$set(this.member.customers[0], "customer_id", null);
      //   this.$set(this.member.customers[0], "first_name", null);
      //   this.$set(this.member.customers[0], "image_path", null);
      //   this.$set(this.member.customers[0], "dob", null);
      //   this.$set(this.member.customers[0], "country_id", null);
      //   this.$set(this.member.customers[0], "last_name", null);
      //   this.$set(this.member.customers[0], "opt_marketing", false);
      //   this.$forceUpdate();
      // }

      // if (data.mobile) {
      //   this.isEmiratesIdCheck = true;
      //   this.$set(this.member.customers[0], "mobile", data.mobile);
      // }
      // if (data.email) this.$set(this.member.customers[0], "email", data.email);
      // if (data.country_id) {
      //   this.$set(this.member.customers[0], "country_id", data.country_id);
      // } else {
      //   this.$set(this.member.customers[0], "country_id", null);
      // }
      // if (data.country_id) {
      //   this.$set(
      //     this.member.customers[0],
      //     "id_proof_type_id",
      //     data.id_proof_type_id
      //   );
      // }

      // if (data.id_proof_number) {
      //   this.$set(
      //     this.member.customers[0],
      //     "id_proof_number",
      //     data.id_proof_number
      //   );
      // }

      // if (data.gender) {
      //   this.$set(this.member.customers[0], "gender", data.gender);
      // } else {
      //   this.$set(this.member.customers[0], "gender", null);
      // }
      // if (data.dob) {
      //   this.$set(this.member.customers[0], "dob", data.dob);
      // } else {
      //   this.$set(this.member.customers[0], "dob", null);
      // }

      // if (data.image) {
      //   this.$set(this.member.customers[0], "image", data.image);
      // }

      // if (data.name) this.$set(this.member.customers[0], "name", data.name);
      // if (data.last_name) {
      //   this.$set(this.member.customers[0], "last_name", data.last_name);
      // } else {
      //   this.$set(this.member.customers[0], "last_name", null);
      // }
      // if (data.first_name)
      //   this.$set(this.member.customers[0], "first_name", data.first_name);
      // if (data.customer_id)
      //   this.$set(this.member.customers[0], "customer_id", data.customer_id);
      // if (data.image_path) {
      //   this.$set(this.member.customers[0], "image_path", data.image_path);
      // } else {
      //   this.$set(this.member.customers[0], "image_path", null);
      // }
      // if (data.opt_marketing) {
      //   if (data.opt_marketing == 1) {
      //     this.$set(this.member.customers[0], "opt_marketing", true);
      //   } else {
      //     this.$set(this.member.customers[0], "opt_marketing", false);
      //   }
      // }
      // this.$forceUpdate();
    },
    setCustomerData(data, index) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (data.mobile && data.first_name && data.customer_id) {
        this.isEmiratesIdCheck = false;
      }
      if (!data.customer_id) {
        this.$set(this.member.customers[index], "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.member.customers[index], "name", data.first_name);
      }
      if (!data.customer_tag) {
        this.$set(this.member.customers[index], "customer_tag", null);
      }

      if (
        this.member.customers[index].customer_id &&
        !data.customer_id &&
        this.member.customers[index].name != data.name &&
        this.member.customers[index].mobile != data.mobile
      ) {
        this.$set(this.member.customers[index], "mobile", null);
        this.member.customers[index].search = null;
        this.member.customers[index].nameSearch = null;
        this.$set(this.member.customers[index], "email", null);
        this.$set(this.member.customers[index], "gender", null);
        this.$set(this.member.customers[index], "name", null);
        this.$set(this.member.customers[index], "customer_id", null);
        this.$set(this.member.customers[index], "first_name", null);
        this.$set(this.member.customers[index], "image_path", null);
        this.$set(this.member.customers[index], "dob", null);
        this.$set(this.member.customers[index], "country_id", null);
        this.$set(this.member.customers[index], "last_name", null);
        this.$set(this.member.customers[index], "opt_marketing", null);
        this.$set(this.bookingForm, "id_proof_type_id", null);
        this.$set(this.bookingForm, "id_proof_number", null);
        this.$set(this.bookingForm, "id_proof_path", null);
        this.$set(this.member.customers[index], "customer_tag", null);

        this.$forceUpdate();
      }

      if (data.mobile) {
        this.$set(this.member.customers[index], "mobile", data.mobile);
      }
      if (data.sales_team_id) {
        this.$set(this.member.sales_team_id, "sales_team_id", data.sales_team_id);
      }
      if (data.customer_tag) {
        this.$set(
          this.member.customers[index],
          "customer_tag",
          data.customer_tag
        );
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.member.customers[index], "customer_tag", null);
        }
      }
      if (data.email) {
        this.$set(this.member.customers[index], "email", data.email);
      }
      if (data.country_id) {
        this.$set(this.member.customers[index], "country_id", data.country_id);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.member.customers[index], "country_id", null);
        }
      }
      if (data.gender) {
        this.$set(this.member.customers[index], "gender", data.gender);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.member.customers[index], "gender", null);
        }
      }
      if (data.dob) {
        this.$set(this.member.customers[index], "dob", data.dob);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.member.customers[index], "dob", null);
        }
      }
      if (data.name) this.$set(this.member.customers[index], "name", data.name);
      if (data.last_name) {
        this.$set(this.member.customers[index], "last_name", data.last_name);
      } else {
        this.$set(this.member.customers[index], "last_name", null);
      }
      if (data.first_name)
        this.$set(this.member.customers[index], "first_name", data.first_name);
      if (data.customer_id)
        this.$set(
          this.member.customers[index],
          "customer_id",
          data.customer_id
        );
      if (data.image_path) {
        this.$set(this.member.customers[index], "image_path", data.image_path);
      } else {
        this.$set(this.member.customers[index], "image_path", null);
      }
      if (data.id_proof_type_id) {
        this.$set(
          this.member.customers[index],
          "id_proof_type_id",
          data.id_proof_type_id
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.member.customers[index], "id_proof_type_id", null);
        }
      }

      if (data.id_proof_path) {
        this.$set(
          this.member.customers[index],
          "id_proof_path",
          data.id_proof_path
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.member.customers[index], "id_proof_path", null);
        }
      }
      if (data.id_proof) {
        this.$set(this.member.customers[index], "id_proof", data.id_proof);
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.member.customers[index], "id_proof", null);
        }
      }
      if (data.opt_marketing) {
        this.$set(
          this.member.customers[index],
          "opt_marketing",
          data.opt_marketing
        );
      } else {
        this.$set(this.member.customers[index], "opt_marketing", null);
      }
      if (data.id_proof_number) {
        this.$set(
          this.member.customers[index],
          "id_proof_number",
          data.id_proof_number
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.member.customers[index], "id_proof_number", null);
        }
      }
      if (data.customer_documents) {
        this.member.customers[index].customer_documents =
          data.customer_documents;
        if (
          data.customer_documents[0] &&
          data.customer_documents[0].id_proof_type_id
        ) {
          this.$set(
            this.member.customers[index],
            "id_proof_type_id",
            data.customer_documents[0].id_proof_type_id
          );
        }
        if (
          data.customer_documents[0] &&
          data.customer_documents[0].id_proof_number
        ) {
          this.$set(
            this.member.customers[index],
            "id_proof_number",
            data.customer_documents[0].id_proof_number
          );
        }
        if (
          data.customer_documents[0] &&
          data.customer_documents[0].id_proof_path
        ) {
          this.$set(
            this.member.customers[index],
            "id_proof_path",
            data.customer_documents[0].id_proof_path
          );
        }
      } else {
        if (data.customer_id) {
          this.member.customers[index].customer_documents = [];
        }
      }
      this.$forceUpdate();
    },
    getFilterUrl() {
      let url = `&page=${this.page}&per_page=${
        this.perPage != null ? this.perPage : 10
      }`;
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (this.searchParams.name != null) {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.tag == "All") {
        delete this.searchParams.tag;
      } else if (this.searchParams.tag != null) {
        url += "&tag=" + this.searchParams.tag;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (this.searchParams.email != null) {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (this.searchParams.mobile != null) {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.membership_package_id == null) {
        delete this.searchParams.mobile;
      } else if (this.searchParams.membership_package_id != null) {
        url +=
          "&membership_package_id=" + this.searchParams.membership_package_id;
      }
      if (this.searchParams.display_card_number == null) {
        delete this.searchParams.display_card_number;
      } else if (this.searchParams.display_card_number != null) {
        url += "&display_card_number=" + this.searchParams.display_card_number;
      }
      if (this.searchParams.card_number == null) {
        delete this.searchParams.card_number;
      } else if (this.searchParams.card_number != null) {
        url += "&card_number=" + this.searchParams.card_number;
      }
      if (this.searchParams.start_date == null) {
        delete this.searchParams.start_date;
      } else if (this.searchParams.start_date != null) {
        url += "&start_date=" + this.searchParams.start_date;
      }
      if (this.searchParams.end_date == null) {
        delete this.searchParams.end_date;
      } else if (this.searchParams.end_date != null) {
        url += "&end_date=" + this.searchParams.end_date;
      }
      if (this.searchParams.status_id == null) {
        delete this.searchParams.status_id;
      } else if (this.searchParams.status_id != null) {
        url += "&status_id=" + this.searchParams.status_id;
      }
      url += `&order_by=${this.orderBy}&sort_order=${this.orderDir}`;
      return url;
    },
    getMembersList() {
      let url = this.getFilterUrl();
      this.$http
        .get(
          `venues/memberships/members?membership_id=${this.membershipId}${url}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.membersList = response.data.data;
            this.membersList.forEach((ele, ii) => {
              this.membersList[ii].installments =
                parseInt(ele.installments) + 1;
              this.membersList[ii].paid_installments =
                parseInt(ele.paid_installments) +
                (ele.order_status == "4" ? 1 : 0);
            });

            this.totalPages = response.data.total_pages;

            //this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
      this.setFieldConfigurations();
    },
    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
        .get(`venues/memberships/members/filters?field=${type}&search=${val}`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            } else if (type == "tag") {
              this.tags = response.data.data;
              this.tags.unshift(val);
              this.tags.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterData() {
      this.page = 1;
      this.getMembersList();
    },
    addOrUpdateMember() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      var formData = new FormData();
      formData.append("membership_id", this.membershipId);
      formData.append("start_date", this.member.start_date);
      formData.append("end_date", this.member.end_date);
      formData.append("tag", this.member.tag);
      if (this.member.sales_team_id)
        formData.append("sales_team_id", this.member.sales_team_id);
      formData.append(
        "membership_package_id",
        this.member.membership_package_id
      );

      formData.append(
        "membership_package_plan_id",
        this.member.membership_package_plan_id
      );

      // if (this.member.contract != null) {
      //   formData.append("contract", this.member.contract);
      // }

      var document = [];
      this.documentFields.forEach((documentField) => {
        if (documentField.file || documentField.isEdit) {
          document.push(documentField);
        }
      });

      document.forEach((element, index) => {
        formData.append(`documents[${index}][name]`, element.name);
        formData.append(`documents[${index}][id]`, element.id);
        if (element.isEdit) {
          formData.append(`documents[${index}][file_path]`, element.file_path);
          formData.append(
            `documents[${index}][document_id]`,
            element.document_id
          );
          formData.append(
            `documents[${index}][original_file_name]`,
            element.original_file_name
          );
        }

        if (element.file) {
          formData.append(`documents[${index}][file]`, element.file);
        }
      });

      if (this.member.order_id != null) {
        formData.append("order_id", this.member.order_id);
      }

      if (this.member.promotion_code)
        formData.append("promotion_code", this.member.promotion_code);
      this.member.customers.forEach((customer, index) => {
        formData.append(`customers[${index}][mobile]`, customer.mobile);
        if (typeof customer.first_name !== "undefined") {
          formData.append(
            `customers[${index}][first_name]`,
            customer.first_name
          );
          if (customer.last_name) {
            formData.append(
              `customers[${index}][last_name]`,
              customer.last_name
            );
          }
        } else {
          if (customer.last_name) {
            formData.append(
              `customers[${index}][first_name]`,
              customer.last_name
            );
          }
        }

        if (customer.member_id != null) {
          formData.append(`customers[${index}][member_id]`, customer.member_id);
        }
        if (customer.customer_tag != null) {
          customer.customer_tag.forEach((tag, key) => {
            formData.append(
              `customers[${index}][customer_tag][${key}][id]`,
              tag.id
            );
            formData.append(
              `customers[${index}][customer_tag][${key}][name]`,
              tag.name
            );
          });
        }

        if (customer.customer_id != null) {
          formData.append(
            `customers[${index}][customer_id]`,
            customer.customer_id
          );
        }
        if (customer.email) {
          formData.append(`customers[${index}][email]`, customer.email);
        }
        if (customer.gender) {
          formData.append(`customers[${index}][gender]`, customer.gender);
        }
        if (customer.country_id) {
          formData.append(
            `customers[${index}][country_id]`,
            customer.country_id
          );
        }
        if (customer.dob) {
          formData.append(`customers[${index}][dob]`, customer.dob);
        }
        formData.append(`customers[${index}][is_primary]`, customer.is_primary);
        if (customer.id_proof_type_id) {
          formData.append(
            `customers[${index}][id_proof_type_id]`,
            customer.id_proof_type_id
          );
        }
        if (customer.id_proof) {
          formData.append(`customers[${index}][id_proof]`, customer.id_proof);
        }
        if (customer.opt_marketing) {
          formData.append(
            `customers[${index}][opt_marketing]`,
            customer.opt_marketing
          );
        } else {
          formData.append(`customers[${index}][opt_marketing]`, 0);
        }
        if (customer.card_number) {
          formData.append(
            `customers[${index}][card_number]`,
            customer.card_number
          );
        }
        if (customer.display_number) {
          formData.append(
            `customers[${index}][display_number]`,
            customer.display_number
          );
        }
        if (customer.image) {
          formData.append(`customers[${index}][profile_image]`, customer.image);
        }
        if (customer.id_proof_number) {
          formData.append(
            `customers[${index}][id_proof_number]`,
            customer.id_proof_number
          );
        }
      });
      this.member.membership_id = this.membershipId;
      this.$http
        .post(`venues/memberships/members/${this.member.operation}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            if (data.status_id != 4) {
              //Not Paid
              this.orderId = response.data.data.id;
            }
            this.hideLoader();
            this.membershipDialoge = false;
            this.$emit("reload");
            this.getMembersList();
            this.showSuccess("Member added successfully");
            this.editFlag = false;
            this.renewOrEdit = false;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    editCustomer(userId) {
      this.userModel.type = "edit";
      this.userModel.userID = parseInt(userId);
    },

    viewMembershipCard(member) {
      this.selectedMemberDetails = member;
      this.showMemberShipCard = true;
    },

    sendMembershipCard(member) {
      this.showLoader("Sending mail....");
      this.$http
        .get(
          `venues/memberships/members/email_membership_card/${member.member_id}`,
          {
            responseType: "blob",
          }
        )
        .then(() => {
          this.hideLoader();
          this.showSuccess(`Membership card details send to ${member.email}`);
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    populateMembershipDetails(orderId, operation) {
      this.showLoader("Loading");
      this.editFlag = true;
      this.renewOrEdit = true;
      this.member = [];
      this.$http
        .get(`venues/memberships/members/details/${orderId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            let pckage = this.packages.find(
              (pkg) => pkg.id == data.membership_package_id
            );
            this.member = data;
            this.checkPackageHasPricing(data.membership_package_id);
            this.member.plan = data.membership_installment_id;
            this.member.membership_package_plan_id =
              data.membership_installment_id;
            this.member.operation = operation;
            data.customers.forEach((customer, index) => {
              if (data.customer_id == customer.customer_id) {
                this.member.customers[index].is_primary = "1";
              } else {
                this.member.customers[index].is_primary = "0";
              }
              if (operation == "renew") {
                this.member.customers[index].card_number = null;
              }
              this.member.customers[index].selected = customer.mobile;
            });

            if (data.documents) {
              data.documents.forEach((document) => {
                this.documentFields.forEach((documentField) => {
                  if (documentField.id == document.felid_id) {
                    documentField.original_file_name =
                      document.original_file_name;
                    documentField.file_path = document.file_path;
                    documentField.document_id = document.id;
                    documentField.isEdit = true;
                  }
                });
              });
            }

            if (operation == "renew") {
              this.renewOrEdit = false;
              this.member.start_date = null;
              this.member.end_date = null;
            }
            this.member.package = pckage;
            this.member.pricing = this.plans.find(
              (x) => x.id === this.member.plan
            );

            this.product = [
              {
                product_id: this.member.plan_data.product_id,
                product_type_id: this.member.plan_data.product.product_type_id,
                price: parseFloat(this.member.price),
                name: this.member.package.name,
                tax: this.member.tax,
                membership_id: this.membershipId,
                quantity: 1,
              },
            ];

            this.$store.dispatch("loadPromotions", {
              date: this.date,
              product_id: this.member.plan_data.product_id,
              membership_id: this.membershipId,
              product_type: "Membership",
            });

            this.membershipDialoge = true;
            if (data.status_id == 4) {
              this.paidFlag = true;
            }

            if (this.promotions && this.promotions.length == 0) {
              this.$store.dispatch("loadPromotions", {
                date: this.date,
                product_id: this.member.plan_data.product_id,
                membership_id: this.membershipId,
                product_type: "Membership",
              });
            }

            this.member.price = data.pre_tax_price;
            this.member.tax = data.tax;
            this.discounted_price = null;
            this.discount_applied = false;
            this.member.promotion_code = null;
            if (data.discount != null) {
              this.discounted_price = data.order_price;
              this.discount_applied = true;

              if (data.discount.promotion != null) {
                this.member.promotion_code =
                  data.discount.promotion.promotion_code;
              }
            }
            this.$forceUpdate();
          }
          this.$forceUpdate();
        })
        .catch((error) => {
          this.errorChecker(error);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    getRenewHistory(userId) {
      this.$http
        .get(
          `venues/memberships/members/history/${userId}?membership_id=${this.membershipId}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.historyDialoge = true;
            this.historyData = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    showReceipt(id) {
      this.orderId = id;
    },
    setEndDate(type) {
      let endDate = moment(this[type].start_date, "YYYY-MM-DD")
        .add(this[type].package.year, "years")
        .add(this[type].package.month, "months")
        .add(this[type].package.day, "days")
        .format("YYYY-MM-DD");
      this.$set(this[type], "end_date", endDate);
    },
    addMemberPopup() {
      this.member = {
        operation: "add",
        package_: null,
        tag: null,
        customers: [
          {
            name: null,
            email: null,
            gender: null,
            country_id: null,
            dob: null,
            id_proof: null,
            id_proof_type_id: null,
            is_primary: 1,
          },
        ],
      };
      this.existingUser = false;
      this.membershipDialoge = true;
      setTimeout(() => {
        this.$refs.form.resetValidation();
      });
    },

    addNewCustomer() {
      if (this.member.package == null) {
        this.showError("Select a package");
        return;
      }
      this.member.customers.push({
        first_name: null,
        last_name: null,
        email: null,
        gender: null,
        country_id: null,
        dob: null,
        id_proof: null,
        id_proof_type_id: null,
        is_primary: 0,
      });
      if (this.member.package.members_limit < this.member.customers.length) {
        this.member.price =
          this.member.price + parseFloat(this.member.package.additional_price);
      }
    },
    checkPackageHasPricing(id = null) {
      this.plans = [];
      if (!id) {
        id = this.member.membership_package_id;
      }
      if (!id) {
        id = this.member.package.id;
      }
      this.showLoader("Fetching data....");
      this.$http
        .get(`venues/memberships/package-pricing/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.hideLoader();
            this.plans = data;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    memberPackagePrice() {
      if (this.member.package != null && this.member.plan != null) {
        this.product = [];
        if (!this.editFlag) {
          this.discounted_price = 0;
          this.discount_applied = false;
          this.promotion = false;
          this.member.promotion_code = false;
        }
        let innerPlan = this.plans.find((x) => x.id === this.member.plan);
        this.member.membership_package_id = this.member.package.id;
        this.member.membership_package_plan_id = this.member.plan;
        this.member.price = parseFloat(innerPlan.price);
        this.member.tax = parseFloat(innerPlan.tax_amount);
        if (
          this.member.customers.length >
          parseInt(this.member.package.members_limit)
        )
          this.member.customers.splice(
            parseInt(this.member.package.members_limit)
          );
        if (this.member.start_date) {
          this.setEndDate("member");
        }
        this.product = [
          {
            product_id: innerPlan.product_id,
            product_type_id: innerPlan.product_type_id,
            price: parseFloat(innerPlan.price),
            name: innerPlan.name,
            tax: innerPlan.tax_amount,
            membership_id: this.membershipId,
            quantity: 1,
          },
        ];

        this.$store.dispatch("loadPromotions", {
          date: this.date,
          product_id: innerPlan.product_id,
          membership_id: this.membershipId,
          product_type: "Membership",
        });
      }
    },
    deleteCustomer(index) {
      this.confirmModel = {
        id: index.toString(),
        title: "Do you want to delete this customer type?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "customer",
      };
    },

    confirmActions(data) {
      if (data.type == "customer") {
        this.member.customers.splice(data.id, 1);
      }

      if (data.type == "Activate") {
        this.showLoader("Activating....");
        this.$http
          .get(`venues/memberships/members/active/${data.id}`, {
            responseType: "blob",
          })
          .then(() => {
            this.hideLoader();
            this.getMembersList();
            this.showSuccess(
              "Member Activated. Membership card details send to email."
            );
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }

      if (data.type == "Deactivate") {
        this.showLoader("Deactivating....");
        this.$http
          .get(`venues/memberships/members/deactivate/${data.id}`, {
            responseType: "blob",
          })
          .then(() => {
            this.hideLoader();
            this.getMembersList();
            this.showSuccess("Member Deactivated.");
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }

      if (data.type == "Freeze") {
        this.mId = data.id;
        this.mfdialog = true;
      } else if (data.type == "UnFreeze") {
        this.mId = data.id;
        this.mfdialog = true;
      }
      if (data.type == "member_upgrade") {
        this.openUpgradeModal(data.id);
      }

      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    exportData() {
      this.showLoader("Wait...");
      let url = this.getFilterUrl();
      this.$http
        .get(
          `venues/memberships/members/export?membership_id=${this.membershipId}${url}`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.downloadFile(response, "Members_Export_");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    verifyBenefit(type) {
      if (this.product.length == 0) {
        this.showError("Please add atleast one product");
        return;
      }
      let data = {
        products: [],
      };
      if (type == "promotion") {
        data.promotion_code = this.member.promotion_code;
        if (data.promotion_code == null) {
          this.clearBenefit();
          return;
        }
      }
      // if (this.registerForm.mobile) {
      //   data.mobile = this.registerForm.mobile;
      // }

      data.products = this.product;
      let url = "venues/benefits/verify";
      this.$http
        .post(url, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;

            if (data.discount) {
              this.discounted_price = data.total;
              this.discount_applied = true;
            }

            //this.data.products = data.products;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    clearBenefit() {
      this.discount_applied = false;
      setTimeout(() => {
        this.$forceUpdate();
      });
    },

    documentUploadRule(field) {
      const rules = [];
      if (field.is_required && !field.isEdit) {
        const rule = (v) => !!v || "Document is required";
        rules.push(rule);
      }
      return rules;
    },
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.getMembersList();
    },

    membershipApproval(member, type) {
      this.confirmModel = {
        id: member.member_id,
        title: `Do you want to ${type} this member?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: type,
      };
    },

    membershipDeactivate(member, type) {
      this.confirmModel = {
        id: member.member_id,
        title: `Do you want to ${type} this member?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: type,
      };
    },
    upgradeMembership(member) {
      this.confirmModel = {
        id: member.member_id,
        title: `Do you want to upgrade?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation. You will not be able to downgrade this member later. Do you need to continue your action ?",
        type: "member_upgrade",
      };
    },
    openUpgradeModal(id) {
      this.upgradeModal = true;
      this.memberId = id;
    },
    closeUpgradeModal(id = null) {
      this.upgradeModal = false;
      this.getMembersList();
      if (id && Number.isInteger(id)) {
        this.invId = id;
      }
      this.memberId = null;
    },

    membershipFreeze(member, type) {
      if (type == "Freeze") {
        type =
          member.status == "Active" && member.freeze_start
            ? "UnFreeze"
            : "Freeze";
      }
      this.freezeData = {
        member_id: member.member_id,
        order_id: member.order_id,
        start_date: member.start_date,
        end_date: member.end_date,
        freeze_start: member.freeze_start,
        freeze_end: member.freeze_end,
        type: type,
      };
      this.mfdialog = true;
    },

    changeIdProofTypeId(data, index = null) {
      if (index !== null) {
        if (
          this.member.customers[index].customer_documents &&
          this.member.customers[index].customer_documents.length
        ) {
          let objType = this.member.customers[index].customer_documents.find(
            (x) => {
              return (
                x.id_proof_type_id ===
                this.member.customers[index].id_proof_type_id
              );
            }
          );
          if (typeof objType !== "undefined" && objType.id_proof_type_id) {
            this.member.customers[index].id_proof_number =
              objType.id_proof_number;
            this.member.customers[index].id_proof_path = objType.id_proof_path;
          } else {
            this.member.customers[index].id_proof_number = null;
            this.member.customers[index].id_proof_path = null;
          }
        } else {
          console.log("document length 0");
        }
      }
    },

    closeMfDialoag() {
      this.mfdialog = false;
      this.$set(this, "freezeData", {});
    },
    getMembeshipFreezeProduct() {
      this.showLoader("Fetching Product details");
      this.$http
        .get(`venues/memberships/members/freeze-product`)
        .then((response) => {
          this.hideLoader();
          if (
            response.status == 200 &&
            response.data.status == true &&
            response.data.data !== ""
          ) {
            this.freezeProduct = response.data.data;
          } else {
            this.freezeProduct = null;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    saveMfData(data) {
      this.mfdialog = false;
      this.$set(this, "freezeData", {});
      if (data && data.type == "Freeze" && data.invoice_id) {
        this.invId = data.invoice_id;
      }
      this.getMembersList();
    },
  },
};
</script>

<style scoped>
.v-select-list >>> .v-subheader {
  font-size: 0.95rem !important;
  font-weight: 600 !important;
  color: black;
}


.personal-trainer_ic {
  background-image: url("../../../assets/images/nav_icon/personal-trainer-grey.png");
  padding: 10px;
  background-size: contain;
}
.member-card {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.member-card span {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
}

.member-all {
  border: 2px solid #0000009e;
}

.member-parent {
  border-bottom: 0px;
}

.member-child {
  border-top: 0px;
}
</style>
