<template>
  <v-container fluid>
    <v-row class="">
      <v-col lg="3" sm="12">
        <BackButton :handler="gotoMembership"/>
      </v-col>
      <v-col lg="6" sm="12">
        <div class="d-flex  gap-x-6 justify-center align-center">
          <div style="max-width: 16rem;max-height: 10rem">
            <view-image :height="160" :image="membership.image_path" :width="320" class="rounded-lg"
                        defaultImage="membership"/>
          </div>
          <div class="mt-4">
            <h3 class="text-xl m-b-4">
              {{ membership.name }}
            </h3>
            <div class="d-flex gap-x-8">
              <SvgIcon class="m-b-4">
                <div>
                  <p class="m-b-0 font-medium">
                    {{ this.totalMembers }}
                  </p>
                  <p class="m-b-0 font-medium text-xs grey--text">
                    Members
                  </p>
                </div>
              </SvgIcon>
              <SvgIcon class="gap-x-4 m-b-4">
                <div>
                  <p class="m-b-0 font-medium">
                    {{ this.totalSales | toCurrency }}
                  </p>
                  <p class="m-b-0 font-medium text-xs grey--text">
                    Sales
                  </p>
                </div>
              </SvgIcon>
              <SvgIcon class="gap-x-4 m-b-4">
                <div>
                  <p class="m-b-0 font-medium">
                    {{ this.totalPackages }}
                  </p>
                  <p class="m-b-0 font-medium text-xs grey--text">
                    No Of Packages
                  </p>
                </div>
              </SvgIcon>
            </div>
          </div>
        </div>
      </v-col>
      <v-col lg="3" sm="12">

        <v-btn
            v-if="checkDeletePermission($modules.memberships.dashboard.slug)"
            :color="membership.status_id == 1?'red':''"
            class="ma-2  text_capitalize "
            outlined
            @click="deleteMembershipWarning({
              id: id,
              type: membership.status_id == 1 ? 'Deactivate' : 'Activate',
            })
            "
        >
          <span class="ml-1">{{ membership.status_id == 1 ? "Deactivate" : "Activate" }}</span>
        </v-btn>
        <v-btn
            v-if="
              checkWritePermission($modules.memberships.dashboard.slug) &&
            membership.status_id == 1"
            class="q-btn-edit m-x-2 text_capitalize"
            text
            @click="editMembership(membership.id)"
        >
          <SvgIcon text="Edit">
            <template v-slot:icon>
              <EditBtnIcon/>
            </template>
          </SvgIcon>
        </v-btn>

      </v-col>
    </v-row>

    <div class="row mt-5">
      <div class="col-12">
        <v-card class="shadow-0">
          <v-card-actions class="text-center ">
            <v-tabs
                v-model="tab"
                background-color="transparent"
                centered
                class="q-tabs-secondary shadow-0 border-bottom"
                light
                slider-color="transparent"
            >
              <v-tab active-class="active_tab_stroke_icon"
                     href="#details"
              >
                <SvgIcon text="Details">
                  <template v-slot:icon>
                    <PackagesIcon/>
                  </template>
                </SvgIcon>

              </v-tab>
              <v-tab active-class="active_tab_fill_icon"
                     class="d-flex " href="#members" v-if="checkReadPermission($modules.memberships.members.slug)">
                <SvgIcon text="Members">
                  <template v-slot:icon>
                    <PeopleIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab active-class="active_tab_stroke_icon"
                     class="d-flex  "
                     href="#sales" v-if="checkReadPermission($modules.memberships.sales.slug)">
                <SvgIcon text="Sales">
                  <template v-slot:icon>
                    <SalesIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab active-class="svg-stroke-neon"
                     class="d-flex  "
                     href="#customer">
                <SvgIcon text="Customer Analysis">
                  <template v-slot:icon>
                    <AnalyticsIcon/>
                  </template>
                </SvgIcon>
              </v-tab>

            </v-tabs>
          </v-card-actions>
          <v-card-text>
            <div class="row">
              <v-tabs-items v-model="tab">
                <v-tab-item value="details">
                  <membership-details
                      :description="membership.description"
                      :durations="membership.durations"
                      :membership-name="membership.name"
                      :membershipCardImage="membershipCardImage"
                      :membershipId="membership.id"
                      :packages="packages"
                      :totalMembers="totalMembers"
                      :totalSales="totalSales"
                      @changeImage="changeImage"
                      @reload="getMembershipDetails"
                  />
                </v-tab-item>

                <v-tab-item value="sales">
                  <membership-sales
                      :exportPermission="
              checkExportPermission($modules.memberships.management.slug)
            "
                      :params="params"
                      :showProductType="false"
                      stackType="all"
                  ></membership-sales>
                </v-tab-item>

                <v-tab-item value="customer">
                  <customer-analysis
                      :exportPermission="
              checkExportPermission($modules.memberships.management.slug)
            "
                      :membership-id="membership.id"
                      :params="params"
                      :showProductType="false"
                      stackType="all"
                  ></customer-analysis>
                </v-tab-item>
                <v-tab-item value="members">
                  <MembershipMembers :membership-name="membership.name"
                                     :membership-status="membership.status_id"
                                     :membershipId="membership.id" :packages="packages"
                  />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>

  </v-container>
</template>

<script>
import CustomerAnalysis from "@/components/Chart/CustomerPieCharts.vue";
import MembershipSales from "@/components/Chart/OrderSalesGraph";
import MembershipDetails from "./MembershipTabs/MembershipDetails";
import MembershipMembers from "./MembershipTabs/Members.vue";
import EditBtnIcon from "@/assets/images/misc/pencil-icon.svg";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import SalesIcon from "@/assets/images/memberships/sales-ico.svg";
import PeopleIcon from "@/assets/images/memberships/members-ico.svg";
import AnalyticsIcon from "@/assets/images/events/analytics-icon.svg";
import PackagesIcon from "@/assets/images/memberships/details.svg";

export default {
  components: {
    PackagesIcon, AnalyticsIcon, SalesIcon, PeopleIcon,
    SvgIcon,
    BackButton,
    EditBtnIcon,
    CustomerAnalysis,
    MembershipSales,
    MembershipDetails,
    MembershipMembers
  },
  created() {
    if (typeof this.$route.params.data != "undefined") {
      this.id = parseInt(atob(this.$route.params.data));
      this.getMembershipDetails();
    }
  },
  data() {
    return {
      tab: "details",
      id: 0,
      image: null,
      membership: {},
      packages: [],
      params: {venue_service_ids: [], product_ids: [], product_type_ids: [2]},
      membershipCardImage: require("@/assets/images/default_images/membership_card.png"),
      totalMembers: 0,
      totalSales: 0,
      totalPackages: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  methods: {
    changeImage(newImage) {
      this.membershipCardImage = newImage;
    },
    editMembership(id) {
      this.$router.push({name: "MembershipEdit", params: {id: id}});
    },
    deleteMembershipWarning(data) {

      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${data.type} this Membership?`,
        description: `By clicking <b>Yes</b> you can confirm ${data.type} this membership.  Do you need to continue your action ?`,
        type: "delete",
      };
    },

    changeMembershipStatus(id) {
      this.$http
          .put(`venues/memberships/${id}/status`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {

              this.getMembershipDetails();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    confirmActions(data) {
      if (data.type == "delete") {
        this.changeMembershipStatus(data.id);
      }
      this.confirmModel.id = null;
    },
    getMembershipDetails() {
      this.showLoader('Loading');
      this.$http
          .get("venues/memberships/" + this.id)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.membership = response.data.data;
              this.totalSales = response.data.data.paid_sales;
              this.totalMembers = response.data.data.paid_members;
              const durations = response.data.data.durations;
              this.totalPackages = durations.length;
              if (this.membership.card_image_path) {
                this.membershipCardImage =
                    this.s3BucketURL + this.membership.card_image_path;
              }
              this.packages = [];
              durations.forEach((item) => {
                let title =
                    item.year > 0
                        ? "Duration: " + item.year + " Years(s) "
                        : "Duration: ";
                title += item.month > 0 ? item.month + " Month(s) " : "";
                title += item.day > 0 ? item.day + " Day(s)" : "";
                this.packages.push({header: title});
                this.packages.push({divider: true});

                item.packages.forEach((pkg) => {
                  let product_ids = pkg.pricing.reduce(function (map, obj) {
                    map.push(obj.product_id);
                    return map;
                  }, []);


                  this.params.product_ids.push(...product_ids);
                  this.packages.push({
                    id: pkg.id,
                    name: pkg.pricing[0].name,
                    type:
                        pkg.membership_type == "I"
                            ? "Individual"
                            : pkg.membership_type == "C"
                                ? "Couple"
                                : "Group",
                    // price: pkg.pricing.map((ele) => {
                    //   return ele.price;
                    // }),
                    price: pkg.pricing.reduce(function (map, obj) {
                      map[`${obj.id}`] = obj.price;
                      return map;
                    }, []),
                    product_id: pkg.product_id,
                    product_type_id: pkg.product_type_id,
                    additional_price: pkg.additional_price,
                    members_limit: pkg.members_limit,
                    year: item.year > 0 ? item.year : 0,
                    month: item.month > 0 ? item.month : 0,
                    day: item.day > 0 ? item.day : 0,
                    tax_amount: pkg.tax_amount,
                  });
                });
                this.packages.push({divider: true});
              });
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader();
      });
    },
    gotoMembership() {
      this.$router.push({
        name: "Memberships",
      });
    },
  },
};
</script>

<style scoped>
.tab_active {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
</style>
