<template>
  <v-container>
    <v-dialog v-model="modal" scrollable width="40%" persistent>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-text>
            <div class="row pt-1 border-bottom">
              <div class="col-md-12">
                <div class="d-flex justify-space-between align-center mt-2">
                  <SvgIcon class="text-2xl font-semibold" text=" Add Members" style="color: black">
                  </SvgIcon>
                  <v-btn fab x-small class="shadow-0" @click="closeModal">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>


            <v-card-text>
              <v-card
                  class="mt-8 bordered"
                  elevation="0"
                  v-for="(customer, index) in customers"
                  :key="index"
              >
                <v-card-actions style="background-color: rgba(248, 250, 251, 1)" class="mb-2">
                  <p class="text-lg font-bold blue-text">Additional Member {{ index + 1 }}</p>
                </v-card-actions>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                        fab
                        x-small
                        absolute
                        top
                        right
                        dark
                        @click="deleteCustomer(index)"
                        v-if="
                        customers.length > 1 &&
                          !customer.member_id
                      "
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
                <v-row dense class="pa-4">
                  <v-col md="4">
                    <image-uploader
                        @upload="
                          (data) => {
                            customer.image = data;
                          }
                        "
                        @remove="
                          () => {
                            customer.image = null;
                          }
                        "
                        :image_path="customer.image_path"
                        :height="240"
                        defaultImage="user"
                        :show-guide="false"
                        message-text=""
                    ></image-uploader>
                  </v-col>
                  <v-col md="8">
                    <v-col cols="12" md="12">
                      <label for="">Customer Mobile*</label>
                      <v-mobile-search
                          v-model="customer.search"
                          :selected="customer.mobile"
                          @updateCustomer="setCustomerData($event, index)"
                          ref="mobile"
                          hide-details="auto"
                          class-name1="q-text-field shadow-0"
                          background-color=""
                          :dense="true"
                          label=""
                          :show-label="false"
                      ></v-mobile-search>
                    </v-col>
                    <v-col cols="12" md="12">
                      <label for="">Customer name*</label>
                      <v-name-search
                          :selected="customer.name"
                          :mobile="customer.mobile"
                          :email="customer.email"
                          v-model="customer.nameSearch"
                          @updateCustomer="setCustomerData($event, index)"
                          ref="name"
                          hide-details="auto"
                          class-name="q-text-field shadow-0"
                          background-color=""
                          :dense="true"
                          label=""
                      ></v-name-search>
                    </v-col>
                  </v-col>
                </v-row>

                <v-row dense class="pa-4">
                  <v-col cols="12" sm="6" md="6">
                    <label for="">Email {{ field.email.is_required ? '*' : '' }}</label>
                    <v-text-field
                        v-model="customer.email"
                        outlined
                        background-color="#fff"
                        :readonly="customer.customer_id != null"
                        required
                        :rules="emailRule"
                        class="q-text-field shadow-0"
                        dense
                        validate-on-blur
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" v-if="field.dob.is_visible">
                    <label for="">Date Of Birth {{ field.dob.is_required ? '*' : '' }}</label>
                    <date-of-birth-field
                        v-model="customer.dob"
                        :rules="dobRule()"
                        :dense="true"
                    >
                    </date-of-birth-field>

                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="field.nationality.is_visible"
                  >
                    <label for="">Nationality{{
                        field.nationality.is_required ? '*' : ''
                      }}</label>
                    <v-autocomplete
                        v-model="customer.country_id"
                        :items="countries"
                        :rules="nationalityRule"
                        item-value="id"
                        item-text="name"
                        outlined
                        background-color="#fff"
                        hide-details="auto"
                        class="q-autocomplete shadow-0"
                        dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="field.gender.is_visible"
                  >
                    <label for="">Gender</label>
                    <v-select
                        v-model="customer.gender"
                        :items="['Male', 'Female']"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        :rules="genderRule"
                        required
                        hide-details="auto"
                        class="q-autocomplete shadow-0"
                        dense
                    ></v-select>
                  </v-col>
                  <v-col sm="6" md="6" v-if="field.idProof.is_visible">
                    <label for="">ID Type{{ field.idProof.is_required ? '*' : '' }}</label>
                    <v-select
                        v-model="customer.id_proof_type_id"

                        :rules="idTypeRule"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        item-value="id"
                        item-text="name"
                        :items="idProofTypes"
                        @change="changeIdProofTypeId($event, index)"

                        hide-details="auto"
                        class="q-autocomplete shadow-0"
                        dense
                    ></v-select>
                  </v-col>
                  <v-col cols="6" v-if="field.idProof.is_visible">
                    <label for="">{{ customer.id_proof_path ? 'Download' : 'ID Proof' }}
                      {{ field.idProof.is_required ? '*' : '' }}</label>
                    <v-file-input
                        :placeholder="
                          `${customer.id_proof_path ? 'Change' : 'Select'}${
                            field.idProof.is_required ? '*' : ''
                          }`
                        "
                        :rules="idProofRule"
                        v-model="customer.id_proof"
                        outlined
                        background-color="#fff"
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon
                        hide-details="auto"
                        class="q-text-field shadow-0"
                        dense
                    >
                      <template v-slot:append>
                        <v-chip
                            class="text-truncate"
                            v-if="
                              !customer.id_proof &&
                                customer.id_proof_path != null
                            "
                            target="_blank"
                            :href="s3BucketURL + customer.id_proof_path"
                            style="width: 97%; transform: translate(0, -18%)"
                        >
                          <v-icon left>mdi-file-download-outline</v-icon>
                          {{ customer.id_proof_file_name }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col sm="6" md="6" v-if="field.idProof.is_visible">
                    <label for="">ID Number{{ field.idProof.is_required ? '*' : '' }}</label>

                    <v-text-field
                        v-model="customer.id_proof_number"
                        :rules="idTypeRule"
                        outlined
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        dense
                        validate-on-blur
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" md="6" v-if="field.tag.is_visible">
                    <label for="">Tags{{ field.tag.is_required ? '*' : '' }}</label>
                    <v-select
                        :items="allTags"
                        outlined
                        item-value="id"
                        item-text="name"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="customer.customer_tag"
                        :rules="tagRule"
                        background-color="#fff"
                        return-object
                        multiple
                        hide-details="auto"
                        class="q-autocomplete shadow-0"
                        dense
                    ></v-select>
                  </v-col>

                  <v-col md="12">
                    <span class="d-flex align-center"><v-checkbox :ripple="false" v-model="customer.opt_marketing"/> Opt In Marketing</span>
                  </v-col>
                </v-row>

              </v-card>
            </v-card-text>


            <v-row class="mt-3">
              <v-col md="12">
                <v-btn color="teal-color shadow-0" dark @click="addNewCustomer">
                  +Add Member
                </v-btn>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-title style="background-color: rgba(231, 234, 236, 1) ; padding: 4px"
          >
            <v-spacer></v-spacer>
            <div class="pitch">
              Total Amount : {{ getPrice() | toCurrency }}
            </div>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color=" "
                class="ma-2  "
                text
                @click="closeModal"
            >Close
            </v-btn
            >
            <v-btn

                color=" darken-1"
                class="ma-2 white--text blue-color" @click="save"
            >Save
            </v-btn
            >
          </v-card-actions>

        </v-card>
      </v-form>
    </v-dialog>
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DateOfBirthField from "@/components/Fields/DateOfBirthField.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import bookingFields from "@/mixins/bookingFieldValidation";

export default {
  name: "AddAdditionalMember.vue",
  mixins: [bookingFields],
  components: {ImageUploader, DateOfBirthField, SvgIcon},
  props: {
    data: {
      type: Object, default: () => {
      }
    },
    modal: {type: Boolean, default: false},
  },
  mounted() {
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags", "normal");
    }
    if (!this.$store.getters.getMembershipTagsByProduct) {
      this.$store.dispatch("loadConfigurations");
    }
    if (!this.$store.getters.getMembershipTagsByProduct) {
      this.$store.dispatch("loadConfigurations");
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }

    this.setFieldConfigurations();
  },
  computed: {
    allTags() {
      return this.$store.getters.getTags.data;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
    membershipTags() {
      return this.$store.getters.getMembershipTagsByProduct;
    },
  },
  data() {
    return {
      customers: [{}],
      valid: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    }
  },
  methods: {
    getPrice() {
      let price = 0;
      let additional = this.data ? this.data.additional_price : 0;
      price = parseFloat(additional) * this.customers.length;
      return price;
    },
    addNewCustomer() {
      this.customers.push({});
    },
    deleteCustomer(index) {
      this.confirmModel = {
        id: index,
        title: "Do you want to delete this customer?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "customer",
      };
    },
    confirmActions(data) {
      if (data.type === "customer") {
        this.customers.splice(data.id, 1);
      }
      this.confirmModel.id = null;
    },
    closeModal() {
      this.customers = [{}];
      this.$emit("close");
    },
    save() {
      let data = {
        customers: this.customers,
        member: this.data
      };
      this.customers = [{}];
      this.$emit("save", data);
    },
    setCustomerData(data, index) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (data.mobile && data.first_name && data.customer_id) {
        this.isEmiratesIdCheck = false;
      }
      if (!data.customer_id) {
        this.$set(this.customers[index], "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.customers[index], "name", data.first_name);
      }
      if (!data.customer_tag) {
        this.$set(this.customers[index], "customer_tag", null);
      }

      if (
          this.customers[index].customer_id &&
          !data.customer_id &&
          this.customers[index].name != data.name &&
          this.customers[index].mobile != data.mobile
      ) {
        this.$set(this.customers[index], "mobile", null);
        this.customers[index].search = null;
        this.customers[index].nameSearch = null;
        this.$set(this.customers[index], "email", null);
        this.$set(this.customers[index], "gender", null);
        this.$set(this.customers[index], "name", null);
        this.$set(this.customers[index], "customer_id", null);
        this.$set(this.customers[index], "first_name", null);
        this.$set(this.customers[index], "image_path", null);
        this.$set(this.customers[index], "dob", null);
        this.$set(this.customers[index], "country_id", null);
        this.$set(this.customers[index], "last_name", null);
        this.$set(this.customers[index], "opt_marketing", null);
        this.$set(this.bookingForm, "id_proof_type_id", null);
        this.$set(this.bookingForm, "id_proof_number", null);
        this.$set(this.bookingForm, "id_proof_path", null);
        this.$set(this.customers[index], "customer_tag", null);

        this.$forceUpdate();
      }

      if (data.mobile) {
        this.$set(this.customers[index], "mobile", data.mobile);
      }
      if (data.customer_tag) {
        this.$set(
            this.customers[index],
            "customer_tag",
            data.customer_tag
        );
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.customers[index], "customer_tag", null);
        }
      }
      if (data.email) {
        this.$set(this.customers[index], "email", data.email);
      }
      if (data.country_id) {
        this.$set(
            this.customers[index],
            "country_id",
            data.country_id
        );
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.customers[index], "country_id", null);
        }
      }
      if (data.gender) {
        this.$set(this.customers[index], "gender", data.gender);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.customers[index], "gender", null);
        }
      }
      if (data.dob) {
        this.$set(this.customers[index], "dob", data.dob);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.customers[index], "dob", null);
        }
      }
      if (data.name)
        this.$set(this.customers[index], "name", data.name);
      if (data.last_name) {
        this.$set(
            this.customers[index],
            "last_name",
            data.last_name
        );
      } else {
        this.$set(this.customers[index], "last_name", null);
      }
      if (data.first_name)
        this.$set(
            this.customers[index],
            "first_name",
            data.first_name
        );
      if (data.customer_id)
        this.$set(
            this.customers[index],
            "customer_id",
            data.customer_id
        );
      if (data.image_path) {
        this.$set(
            this.customers[index],
            "image_path",
            data.image_path
        );
      } else {
        this.$set(this.customers[index], "image_path", null);
      }
      if (data.id_proof_type_id) {
        this.$set(
            this.customers[index],
            "id_proof_type_id",
            data.id_proof_type_id
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(
              this.customers[index],
              "id_proof_type_id",
              null
          );
        }
      }

      if (data.id_proof_path) {
        this.$set(
            this.customers[index],
            "id_proof_path",
            data.id_proof_path
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.customers[index], "id_proof_path", null);
        }
      }
      if (data.id_proof) {
        this.$set(
            this.customers[index],
            "id_proof",
            data.id_proof
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.customers[index], "id_proof", null);
        }
      }
      if (data.opt_marketing) {
        this.$set(
            this.customers[index],
            "opt_marketing",
            data.opt_marketing
        );
      } else {
        this.$set(this.customers[index], "opt_marketing", null);
      }
      if (data.id_proof_number) {
        this.$set(
            this.customers[index],
            "id_proof_number",
            data.id_proof_number
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(
              this.customers[index],
              "id_proof_number",
              null
          );
        }
      }
      if (data.customer_documents) {
        this.customers[index].customer_documents =
            data.customer_documents;
        if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_type_id
        ) {
          this.$set(
              this.customers[index],
              "id_proof_type_id",
              data.customer_documents[0].id_proof_type_id
          );
        }
        if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_number
        ) {
          this.$set(
              this.customers[index],
              "id_proof_number",
              data.customer_documents[0].id_proof_number
          );
        }
        if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_path
        ) {
          this.$set(
              this.customers[index],
              "id_proof_path",
              data.customer_documents[0].id_proof_path
          );
        }
      } else {
        if (data.customer_id) {
          this.customers[index].customer_documents = [];
        }
      }
      this.$forceUpdate();
    },
  }
}
</script>
<style scoped>

</style>