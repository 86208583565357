<template>
    <div>
      <v-dialog v-model="mfdialog" scrollable width="600" @input="closeEdit" class="mf-freeze-dialog" persistent>
      <v-card>
        <v-card-title class="headline">Membership {{freezeData && freezeData.type == 'UnFreeze'?'Un': ''}}Freeze</v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="freezeData && freezeData.type == 'Freeze'">
              <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Freeze Price / Month"
                    :value="freezeProduct.total_price"
                    outlined
                    :disabled="true"
                    background-color="#fff"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Total Price"
                    :value="totalFreezePrice"
                    outlined
                    :readonly="true"
                    background-color="#fff"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                  <date-field
                      v-model="freeze_start"
                      :rules="[(v) => !!v || 'Freeze date is required']"
                      label="Freeze Start Date*"
                      :dayName="true"
                  ></date-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="No. Of Days"
                    v-model="days"
                    outlined
                    background-color="#fff"
                    :rules="[
                      (v) => !!v || 'Days is required',
                      (v) => !isNaN(v) || 'Days must be Number',
                      (v) => (v > 0 && v <= 365 ) || 'Minimun day is 1 and max 365',
                    ]"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="freezeData && freezeData.type == 'UnFreeze'">
                <v-col cols="12" sm="12" md="12">
                  <h3 class="py-2">Membership freezed at: {{ getFreezeStartFormated(freezeData.freeze_start) }}<br></h3>
                  <h3 class="py-2">Membership freezed for days: {{ getFreezeEndDays(freezeData.freeze_start,freezeData.freeze_end) }}</h3>
                  <h3 class="py-2">Total days freezed: {{ getFreezedTotalDays(freezeData.freeze_start) }}</h3>
                  <h3 class="py-2">Total days adjustment: {{ getTotalExtendedDays(freezeData.freeze_start,freezeData.end_date) }}</h3>
                  <span class=""><i>Total adjustement days will be used for extends membership expiry.</i></span>
                </v-col>
              </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="closeEdit"
            >Close</v-btn
          >
          <v-btn
            color=" darken-1"
            class="ma-2 white--text teal-color"
            text
            v-if="checkWritePermission($modules.memberships.freeze.slug)"
            @click="freezeMembership()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    oId: { type: Number, default: null },
    mId: { type: Number, default: null },
    type: { type: String, default: "details" },
    mfdialog: { type: Boolean, default: false },
    freezeData: { type: Object },
    freezeProduct: {type: Object}
  },
  data() {
    return {
      valid: false,
      freeze_start: this.freezeData && this.freezeData.freeze_start?this.freezeData.freeze_start:moment().format("YYYY-MM-DD"),
      days: null,
    };
  },
  watch: {
    freezeData(val) {
      if (val != null) {
        if(val.freeze_start && val.freeze_end && val.freeze_start != null && val.freeze_end != null){
          this.freeze_start = val.freeze_start;
          let d = this.getFreezeEndDays(val.freeze_start,val.freeze_end);
          this.days = d;
        }
      }
    },
  },
  mounted() {},
  computed: {
    totalFreezePrice(){
      if(this.freezeProduct && this.freezeProduct.total_price){
        if(this.freeze_start && this.days){
          let ftd = this.calculateMonthDiff(this.freeze_start,this.days);
          return ftd * this.freezeProduct.total_price;
        }else{
          return this.freezeProduct.total_price;
        }
      }else{
        return 0;
      }

    }
  },
  methods: {
    freezeMembership() {
      this.showLoader('saving...');
      if(this.freezeData.type == "Freeze"){
        if(this.days <= 0){
          this.showError("Number of days required");
          this.hideLoader();
          return;
        }
        var start_date = moment(this.freeze_start);
        var data = {};
        data = {
          member_id: this.freezeData.member_id,
          order_id: this.freezeData.order_id,
          freeze_start: start_date.format("Y-MM-DD"),
          freeze_end: start_date.clone().add(this.days, 'days').format('Y-MM-DD'),
          type:this.freezeData.type,
          product_id: this.freezeProduct.id,
          total_price: this.totalFreezePrice,
        };
      }else if(this.freezeData.type == "UnFreeze"){
        data = {
          member_id: this.freezeData.member_id,
          order_id: this.freezeData.order_id,
          type:this.freezeData.type
        };
      }
      this.$http
        .post(`venues/memberships/members/freeze`, data)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess(response.data.message);
            const data = response.data.data;
            this.days = null;
            this.hideLoader();
            let res = {id: "", type: this.freezeData.type};
            if(data && data.id){
              res.invoice_id = data.id;
            }
            this.$emit('saveMfData',res);
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        })
    },
    getFreezeStartFormated(freeze_start){
      return moment(freeze_start).format("Do MMM, YYYY");
    },
    getFreezeEndFormated(freeze_end){
      return moment(freeze_end).format("Do MMM, YYYY");
    },
    getFreezeEndDays(freeze_start,freeze_end){
      var startDate = moment(freeze_start);
      var endDate = moment(freeze_end);
      var daysDifference = endDate.diff(startDate, 'days');
      return daysDifference;
    },
    getFreezedTotalDays(freeze_start){
      var startDate = moment(freeze_start);
      let today = moment();
      return startDate <= today?today.diff(startDate, 'days'):0;
    },
    getTotalExtendedDays(freeze_start,m_end_date){
        let ftd = this.getFreezedTotalDays(freeze_start);
        var startDate = moment(freeze_start);
        var endDate = moment(m_end_date);
        // Calculate the difference in days
        var remainingDays = endDate.diff(startDate, 'days');
        return remainingDays >= ftd?ftd:remainingDays;
    },
    closeEdit() {
      this.freeze_start = moment().format("YYYY-MM-DD");
      this.days=null;
      this.$emit("close");
    },
    setCustomerData(data) {
      this.$emit("setCustomerData", data);
    },
    calculateMonthDiff(freeze_start,no_days){
      var startDate = moment(freeze_start);
      var endDate = startDate.clone().add(no_days, 'days');

      // Calculate the difference in total months
      var monthsDifference = endDate.diff(startDate, 'months', true);

      // Calculate full months and remaining days based on 30 days per month
      // var fullMonths = Math.floor(monthsDifference);
      // var remainingDays = Math.round((monthsDifference - fullMonths) * 30);
 
      return Math.ceil(monthsDifference); 
    }
  },
};
</script>

<style scoped>
.mf-action-heading {
    color: var(--dark-blue, #112A46);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 18px;
}

form.v-form.mf-form {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}

button.ma-2.text.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-skip-btn {
    color: #A7A7A7;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

button.ma-2.white--text.blue-color.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-btn-next {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 75px;
    height: 40px;
}
/deep/ .v-dialog {
    overflow-y: unset;
}
</style>
