<template>
  <v-container>
    <v-dialog v-model="upgradeModal" scrollable width="40%" persistent>
      <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-text>
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text=" Membership Upgrade" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0"   @click="closeModal">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <v-row>
            <v-col md="12">
              <div class="font-semibold text-base text-blue">Package details</div>
            </v-col>
          </v-row>
          <v-divider class="mt-2 "/>
          <v-row class="mt-2">
            <v-col cols="6">
              <label for="">Membership</label>
              <v-select
                  :items="memberships"
                  return-object
                  item-text="name"
                  item-value="id"
                  v-model="newMembership.membership"
                  @change="getPackages()"
                  outlined
                  :rules="[(v) => !!v || 'Membership is required']"
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  hide-details
                  dense
                  validate-on-blur
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <label for="">Packages</label>
              <v-select
                  :items="packages"
                  return-object
                  item-text="name"
                  item-value="id"
                  v-model="newMembership.package"
                  @change="getPlans()"
                  outlined
                  :rules="[(v) => !!v || 'Package is required']"
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  hide-details
                  dense
                  validate-on-blur
              >
                <template slot="item" slot-scope="data">
                  <template v-if="!(typeof data.item == 'object')">
                    <v-list-item-content style="text-align: center">
                      {{ data.item }}
                    </v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.subtext }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6">
              <label for="">Plan</label>

              <v-select
                  :items="plans"
                  item-text="name"
                  item-value="id"
                  v-model="newMembership.plan"
                  outlined
                  :rules="[(v) => !!v || 'Plan is required']"
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  hide-details
                  dense
                  validate-on-blur
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <label for="">Start Date</label>

              <date-field
                  v-model="newMembership.start_date"

                  @change="setEndDate()"
                  class-name="q-text-field shadow-0"
                  :hide-details="true"
                  :dense="true"
                  bg-color=""
                  label=""
              ></date-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <label for="">End Date</label>

              <date-field

                  :min="
                      newMembership.start_date
                        ? newMembership.start_date
                        : today
                    "
                  v-model="newMembership.end_date"
                  disabled
                  class-name="q-text-field shadow-0"
                  :hide-details="true"
                  :dense="true"
                  bg-color=""
                  label=""
              ></date-field>
            </v-col>
            <v-col sm="6">
              <label for="">Tag</label>
              <v-select
                  v-model="newMembership.tag"
                  :items="membershipTags"
                  item-text="name"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  hide-details
                  dense
                  validate-on-blur
              ></v-select>
            </v-col>
          </v-row>


          <v-row class="mt-2" >
            <div class="col-md-12">
              <div class="d-flex align-center justify-space-between">
                <div class="font-semibold text-base text-blue">Member details</div>
              </div>
            </div>
          </v-row>

          <v-card
              class="mt-8 bordered"
              elevation="0"
              v-for="(customer, index) in newMembership.customers"
              :key="index"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    fab
                    x-small
                    absolute
                    top
                    right
                    dark
                    @click="deleteCustomer(index)"
                    v-if="
                        newMembership.customers.length > 1 &&
                          !customer.member_id
                      "
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              Delete
            </v-tooltip>

            <v-card-actions style="background-color: rgba(248, 250, 251, 1)"  v-if="newMembership.customers.length > 1"  >
              <p class="text-lg font-bold blue-text">Member {{ index + 1 }}</p>
            </v-card-actions>
            <v-card-text>

              <v-row dense>
                <v-col md="12">
                  <div class="d-flex justify-space-between" >
                    <div class="d-flex"  v-if="index > 0">
                      <card-reader-button
                          className="blue-text mt-5"
                          label="Samsotech Reader"
                          docType="chip"

                          @data="
                          (data) => {
                            setCardData(data, index);
                          }
                        "
                      ></card-reader-button>
                      <card-data-button
                          className="blue-text mt-5 ml-4"
                          label="HID Omnikey"
                          @data="
                          (data) => {
                            setCardData(data, index);
                          }
                        "
                      ></card-data-button>
                    </div>
                    <div v-else></div>


                    <div class="d-flex">
                      <v-switch
                          true-value="1"
                          false-value="0"
                          label="Primary Member"
                          v-model="customer.is_primary"
                          class="text-right"
                      ></v-switch>
                    </div>
                  </div>
                </v-col>
              </v-row>


              <v-row dense>
                <v-col md="4">
                 <image-uploader
                    @upload="
                          (data) => {
                            customer.image = data;
                          }
                        "
                    @remove="
                          () => {
                            customer.image = null;
                          }
                        "
                    :image_path="customer.image_path"
                    :height="240"
                    defaultImage="user"
                    :show-guide="false"
                    message-text=""
                ></image-uploader>
                </v-col>
                <v-col md="8">
                  <v-col cols="12" md="12">
                    <label for="">Customer Mobile*</label>
                    <v-mobile-search
                        v-model="customer.search"
                        :selected="customer.mobile"
                        @updateCustomer="setCustomerData($event, index)"
                        ref="mobile"
                        hide-details="auto"
                        class-name1="q-text-field shadow-0"
                        background-color=""
                        :dense="true"
                        label=""
                        :show-label="false"
                    ></v-mobile-search>
                  </v-col>
                  <v-col cols="12"  md="12">
                    <label for="">Customer name*</label>
                    <v-name-search
                        :selected="customer.name"
                        :mobile="customer.mobile"
                        :email="customer.email"
                        v-model="customer.nameSearch"
                        @updateCustomer="setCustomerData($event, index)"
                        ref="name"
                        hide-details="auto"
                        class-name="q-text-field shadow-0"
                        background-color=""
                        :dense="true"
                        label=""
                    ></v-name-search>
                  </v-col>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <label for="">Email {{field.email.is_required ? '*' : ''}}</label>
                  <v-text-field
                      v-model="customer.email"
                      outlined
                      background-color="#fff"
                      readonly
                      required
                      :rules="emailRule"
                      class="q-text-field shadow-0"
                      dense
                      validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col md="6" v-if="field.dob.is_visible">
                  <label for="">Date Of Birth {{field.dob.is_required ? '*' : ''}}</label>
                  <date-of-birth-field
                      v-model="customer.dob"
                      :rules="dobRule()"
                      :dense="true"
                  >
                  </date-of-birth-field>

                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    v-if="field.nationality.is_visible"
                >
                  <label for="">Nationality{{
                      field.nationality.is_required ? '*' : ''
                    }}</label>
                  <v-autocomplete
                      v-model="customer.country_id"
                      :items="countries"
                      :rules="nationalityRule"
                      item-value="id"
                      item-text="name"
                      outlined
                      background-color="#fff"
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                      dense
                  ></v-autocomplete>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    v-if="field.gender.is_visible"
                >
                  <label for="">Gender</label>
                  <v-select
                      v-model="customer.gender"
                      :items="['Male', 'Female']"
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      background-color="#fff"
                      :rules="genderRule"
                      required
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                      dense
                  ></v-select>
                </v-col>
                <v-col sm="6" md="6" v-if="field.idProof.is_visible">
                  <label for="">ID Type{{field.idProof.is_required ? '*' : ''}}</label>
                  <v-select
                      v-model="customer.id_proof_type_id"

                      :rules="idTypeRule"
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      background-color="#fff"
                      item-value="id"
                      item-text="name"
                      :items="idProofTypes"
                      @change="changeIdProofTypeId($event, index)"

                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="6" v-if="field.idProof.is_visible">
                  <label for="">{{customer.id_proof_path ? 'Download' : 'ID Proof'}} {{field.idProof.is_required ? '*' : ''}}</label>
                  <v-file-input
                      :placeholder="
                          `${customer.id_proof_path ? 'Change' : 'Select'}${
                            field.idProof.is_required ? '*' : ''
                          }`
                        "
                      :rules="idProofRule"
                      v-model="customer.id_proof"
                      outlined
                      background-color="#fff"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon
                      hide-details="auto"
                      class="q-text-field shadow-0"
                      dense
                  >
                    <template v-slot:append>
                      <v-chip
                          class="text-truncate"
                          v-if="
                              !customer.id_proof &&
                                customer.id_proof_path != null
                            "
                          target="_blank"
                          :href="s3BucketURL + customer.id_proof_path"
                          style="width: 97%; transform: translate(0, -18%)"
                      >
                        <v-icon left>mdi-file-download-outline</v-icon>
                        {{ customer.id_proof_file_name }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <!-- <v-chip
                    v-if="customer.id_proof_path != null"
                    target="_blank"
                    :href="s3BucketURL + customer.id_proof_path"
                  >
                    <v-icon left>mdi-file-download-outline</v-icon>
                    {{ customer.id_proof_file_name }}
                  </v-chip> -->
                </v-col>
                <v-col sm="6" md="6" v-if="field.idProof.is_visible">
                  <label for="">ID Number{{field.idProof.is_required ? '*' : ''}}</label>

                  <v-text-field
                      v-model="customer.id_proof_number"
                      :rules="idTypeRule"
                      outlined
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      dense
                      validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col sm="6" md="6" v-if="field.tag.is_visible">
                  <label for="">Tags{{field.tag.is_required ? '*' : ''}}</label>
                  <v-select
                      :items="allTags"
                      outlined
                      item-value="id"
                      item-text="name"
                      :menu-props="{ bottom: true, offsetY: true }"
                      v-model="customer.customer_tag"
                      :rules="tagRule"
                      background-color="#fff"
                      return-object
                      multiple
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                      dense
                  ></v-select>
                </v-col>

                <v-col md="12">
                  <span class="d-flex align-center"><v-checkbox :ripple="false"  v-model="customer.opt_marketing"/> Opt In Marketing</span>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>


          <v-row v-if="allowAdditionalMember" class="mt-3">
            <v-col md="12">
              <v-btn color="teal-color shadow-0"  dark @click="addNewCustomer">
                +Add Member
              </v-btn>
            </v-col>
          </v-row>



        </v-card-text>
        <v-card-title style="background-color: rgba(231, 234, 236, 1) ; padding: 4px"
          >
          <v-spacer></v-spacer>
          <div class="pitch">
            Utilized Amount : {{ Number(utilizedAmount) | toCurrency }} /
            {{ originalPrice }}
          </div>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=" "
              class="ma-2  "
              text
              @click="closeModal"
            >Close</v-btn
          >
          <v-btn

              color=" darken-1"
              class="ma-2 white--text blue-color" @click="getCalculations"
            >Calculate</v-btn
          >
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="calculationModal" scrollable width="30%">
      <v-card>
        <v-card-title class="headline">
          Upgrading calculations
        </v-card-title>
        <v-card-text style="margin-top:20px">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h3 class="py-2" style="font-weight:400">
                Dates
                <b>{{ newMembership.start_date }}</b> to
                <b>{{ newMembership.end_date }}</b>
              </h3>
              <h3 class="py-2" style="font-weight:400">
                You have utilized
                <b>{{ calculations.utilizedAmount | toCurrency }}</b> out of
                total
                <b>{{ calculations.originalTotal | toCurrency }}</b>
              </h3>
              <br />
              <h3 class="py-2" style="font-weight:400">
                New Total is <b>{{ calculations.newTotal | toCurrency }}</b>
              </h3>
              <br />
              <h3 class="py-2" style="font-weight:400">
                Pro Rata total is
                <b>{{ calculations.proRatePrice | toCurrency }}</b>
              </h3>
              <br />
              <h3 class="py-2" style="font-weight:400">
                New Payment needed is
                <b>{{ calculations.difference | toCurrency }}</b>
              </h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 white--text blue-color" @click="closeModal"
            >Cancel</v-btn
          >
          <v-btn class="ma-2 white--text teal-color" @click="upgradeMembership"
            >Upgrade</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>
<script>
import moment from "moment";
import bookingFields from "@/mixins/bookingFieldValidation";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import DateOfBirthField from "@/components/Fields/DateOfBirthField.vue";
export default {
  components: {DateOfBirthField, ImageUploader, SvgIcon},
  props: {
    id: { type: Number, default: 0 },
    upgradeModal: { type: Boolean, default: false },
  },
  data() {
    return {
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      primary_customer: null,
      memberships: [],
      packages: [],
      calculationModal: false,
      plans: [],
      originalPrice: 0,
      utilizedAmount: 0,
      ProRataAmount: 0,
      difference: 0,
      invoiceModel: { invoiceId: null, type: "details", orderIds: [] },
      valid: false,
      today: moment().format("YYYY-MM-DD"),
      newMembership: {
        start_date: null,
        end_date: null,
        tag_id: null,
        membership: null,
        package: null,
        plan: null,
        customers: [],
        status: null,
      },
      calculations: {
        proRatePrice: 0,
        difference: 0,
        utilizedAmount: 0,
        originalTotal: 0,
        newTotal: 0,
      },
    };
  },
  watch: {
    upgradeModal(val) {
      if (val === true) {
        this.getMemberships();
      }
    },
  },
  mixins: [bookingFields],
  mounted() {
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags", "normal");
    }
    if (!this.$store.getters.getMembershipTagsByProduct) {
      this.$store.dispatch("loadConfigurations");
    }
    if (!this.$store.getters.getMembershipTagsByProduct) {
      this.$store.dispatch("loadConfigurations");
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }

    this.setFieldConfigurations();
    // this.setDocumentFields();
  },
  computed: {
    allTags() {
      return this.$store.getters.getTags.data;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
    membershipTags() {
      return this.$store.getters.getMembershipTagsByProduct;
    },
    allowAdditionalMember() {
      if (this.newMembership.package != null) {
        if (
          this.newMembership.package.members_limit >
            this.newMembership.customers.length ||
          this.newMembership.package.additional_price != null
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.newMembership.package != null) {
        if (
          this.newMembership.package.members_limit >
          this.newMembership.customers.length
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
  },
  methods: {
    deleteCustomer(index) {
      this.confirmModel = {
        id: index,
        title: "Do you want to delete this customer?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "customer",
      };
    },
    confirmActions(data) {
      if (data.type == "customer") {
        this.newMembership.customers.splice(data.id, 1);
      }
      this.confirmModel.id = null;
    },


    setEndDate() {
      let endDate = moment(this.newMembership.start_date, "YYYY-MM-DD")
        .add(this.newMembership.package.year, "years")
        .add(this.newMembership.package.month, "months")
        .add(this.newMembership.package.day, "days")
        .format("YYYY-MM-DD");
      this.$set(this.newMembership, "end_date", endDate);
    },

    changeIdProofTypeId(event,index){
      this.$emit('changeIdProofTypeId',event,index);
    },
    getCalculations() {
      if (this.newMembership.membership == null) {
        this.showError("Please select a membership");
        return;
      }
      if (this.newMembership.package == null) {
        this.showError("Please select a package");
        return;
      }
      if (this.newMembership.plan == null) {
        this.showError("Please select a plan");
        return;
      }
      this.showLoader("Calculating Pro rata amount...");

      this.calculations.proRatePrice = 0;
      this.calculations.difference = 0;
      this.calculations.utilizedAmount = 0;
      this.calculations.originalTotal = 0;
      this.calculations.newTotal = 0;

      this.$http
        .post(
          "venues/memberships/members/upgrade/get-upgrade-membership-calculations",
          {
            data: this.newMembership,
            utilized_amount: this.utilizedAmount,
            originalPrice: this.originalPrice,
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.calculations.proRatePrice = response.data.proRatePrice;
            this.calculations.difference = response.data.difference;
            this.calculations.utilizedAmount = response.data.utilizedPrice;
            this.calculations.originalTotal = response.data.originalTotal;
            this.calculations.newTotal = response.data.newTotal;
            this.calculationModal = true;
            console.log(data);
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    upgradeMembership() {
      if (this.newMembership.membership == null) {
        this.showError("Please select a membership");
        return;
      }
      if (this.newMembership.package == null) {
        this.showError("Please select a package");
        return;
      }
      if (this.newMembership.plan == null) {
        this.showError("Please select a plan");
        return;
      }
      this.showLoader("Upgrading membership...");
      this.$http
        .post("venues/memberships/members/upgrade/upgrade-membership", {
          data: this.newMembership,
          calculations: this.calculations,
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.closeModal(data);
            this.showSuccess("Membership upgraded successfully");
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    openInvoice(invoice_id, type = "view") {
      this.showLoader("Loading..");
      this.$http
        .get("venues/invoices/" + invoice_id)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            let data = response.data.data;
            if (type == "view") {
              this.invoiceData = data;
              this.invoiceModel.invoiceId = data.id;
              this.invoiceModel.orderIds = [];
            }
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    setCustomerData(data, index) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (data.mobile && data.first_name && data.customer_id) {
        this.isEmiratesIdCheck = false;
      }
      if (!data.customer_id) {
        this.$set(this.newMembership.customers[index], "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.newMembership.customers[index], "name", data.first_name);
      }
      if (!data.customer_tag) {
        this.$set(this.newMembership.customers[index], "customer_tag", null);
      }

      if (
        this.newMembership.customers[index].customer_id &&
        !data.customer_id &&
        this.newMembership.customers[index].name != data.name &&
        this.newMembership.customers[index].mobile != data.mobile
      ) {
        this.$set(this.newMembership.customers[index], "mobile", null);
        this.newMembership.customers[index].search = null;
        this.newMembership.customers[index].nameSearch = null;
        this.$set(this.newMembership.customers[index], "email", null);
        this.$set(this.newMembership.customers[index], "gender", null);
        this.$set(this.newMembership.customers[index], "name", null);
        this.$set(this.newMembership.customers[index], "customer_id", null);
        this.$set(this.newMembership.customers[index], "first_name", null);
        this.$set(this.newMembership.customers[index], "image_path", null);
        this.$set(this.newMembership.customers[index], "dob", null);
        this.$set(this.newMembership.customers[index], "country_id", null);
        this.$set(this.newMembership.customers[index], "last_name", null);
        this.$set(this.newMembership.customers[index], "opt_marketing", null);
        this.$set(this.bookingForm, "id_proof_type_id", null);
        this.$set(this.bookingForm, "id_proof_number", null);
        this.$set(this.bookingForm, "id_proof_path", null);
        this.$set(this.newMembership.customers[index], "customer_tag", null);

        this.$forceUpdate();
      }

      if (data.mobile) {
        this.$set(this.newMembership.customers[index], "mobile", data.mobile);
      }
      if (data.customer_tag) {
        this.$set(
          this.newMembership.customers[index],
          "customer_tag",
          data.customer_tag
        );
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.newMembership.customers[index], "customer_tag", null);
        }
      }
      if (data.email) {
        this.$set(this.newMembership.customers[index], "email", data.email);
      }
      if (data.country_id) {
        this.$set(
          this.newMembership.customers[index],
          "country_id",
          data.country_id
        );
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.newMembership.customers[index], "country_id", null);
        }
      }
      if (data.gender) {
        this.$set(this.newMembership.customers[index], "gender", data.gender);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.newMembership.customers[index], "gender", null);
        }
      }
      if (data.dob) {
        this.$set(this.newMembership.customers[index], "dob", data.dob);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.newMembership.customers[index], "dob", null);
        }
      }
      if (data.name)
        this.$set(this.newMembership.customers[index], "name", data.name);
      if (data.last_name) {
        this.$set(
          this.newMembership.customers[index],
          "last_name",
          data.last_name
        );
      } else {
        this.$set(this.newMembership.customers[index], "last_name", null);
      }
      if (data.first_name)
        this.$set(
          this.newMembership.customers[index],
          "first_name",
          data.first_name
        );
      if (data.customer_id)
        this.$set(
          this.newMembership.customers[index],
          "customer_id",
          data.customer_id
        );
      if (data.image_path) {
        this.$set(
          this.newMembership.customers[index],
          "image_path",
          data.image_path
        );
      } else {
        this.$set(this.newMembership.customers[index], "image_path", null);
      }
      if (data.id_proof_type_id) {
        this.$set(
          this.newMembership.customers[index],
          "id_proof_type_id",
          data.id_proof_type_id
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(
            this.newMembership.customers[index],
            "id_proof_type_id",
            null
          );
        }
      }

      if (data.id_proof_path) {
        this.$set(
          this.newMembership.customers[index],
          "id_proof_path",
          data.id_proof_path
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.newMembership.customers[index], "id_proof_path", null);
        }
      }
      if (data.id_proof) {
        this.$set(
          this.newMembership.customers[index],
          "id_proof",
          data.id_proof
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.newMembership.customers[index], "id_proof", null);
        }
      }
      if (data.opt_marketing) {
        this.$set(
          this.newMembership.customers[index],
          "opt_marketing",
          data.opt_marketing
        );
      } else {
        this.$set(this.newMembership.customers[index], "opt_marketing", null);
      }
      if (data.id_proof_number) {
        this.$set(
          this.newMembership.customers[index],
          "id_proof_number",
          data.id_proof_number
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(
            this.newMembership.customers[index],
            "id_proof_number",
            null
          );
        }
      }
      if (data.customer_documents) {
        this.newMembership.customers[index].customer_documents =
          data.customer_documents;
        if (
          data.customer_documents[0] &&
          data.customer_documents[0].id_proof_type_id
        ) {
          this.$set(
            this.newMembership.customers[index],
            "id_proof_type_id",
            data.customer_documents[0].id_proof_type_id
          );
        }
        if (
          data.customer_documents[0] &&
          data.customer_documents[0].id_proof_number
        ) {
          this.$set(
            this.newMembership.customers[index],
            "id_proof_number",
            data.customer_documents[0].id_proof_number
          );
        }
        if (
          data.customer_documents[0] &&
          data.customer_documents[0].id_proof_path
        ) {
          this.$set(
            this.newMembership.customers[index],
            "id_proof_path",
            data.customer_documents[0].id_proof_path
          );
        }
      } else {
        if (data.customer_id) {
          this.newMembership.customers[index].customer_documents = [];
        }
      }
      this.$forceUpdate();
    },
    addNewCustomer() {
      if (this.newMembership.package == null) {
        this.showError("Please select a package");
        return;
      }
      this.newMembership.customers.push({
        first_name: null,
        last_name: null,
        email: null,
        gender: null,
        country_id: null,
        dob: null,
        id_proof: null,
        id_proof_type_id: null,
        is_primary: 0,
      });

      if (
        this.newMembership.package.members_limit <
        this.newMembership.customers.length
      ) {
        this.newMembership.price =
          this.newMembership.price +
          parseFloat(this.newMembership.package.additional_price);
      }
    },
    closeModal(id = null) {
      this.newMembership = {
        start_date: null,
        end_date: null,
        tag_id: null,
        membership: null,
        package: null,
        plan: null,
        customers: [],
        status: null,
      };
      this.calculationModal = false;
      this.calculations = {
        proRatePrice: 0,
        difference: 0,
        utilizedAmount: 0,
        originalTotal: 0,
        newTotal: 0,
      };
      this.$refs.form.resetValidation();
      this.$emit("close", id);
    },
    async getMemberships() {
      this.showLoader("Loading memberships");
      await this.$http
        .get(
          `venues/memberships/members/upgrade/get-all-memberships/${this.id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.memberships = data;
            this.hideLoader();
            this.getUtilizedAmount();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    getUtilizedAmount() {
      this.$http
        .get(
          `venues/memberships/members/upgrade/get-utilized-amount/${this.id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.primary_customer = response.data.member.primary_customer;
            this.utilizedAmount = data;
            this.originalPrice = response.data.total;
            this.newMembership.start_date = response.data.start_date;
            this.newMembership.end_date = response.data.end_date;
            this.newMembership.customers = response.data.allMembers;

            this.newMembership.customers.forEach((ele) => {
              ele.name =
                ele.first_name + (ele.last_name ? " " + ele.last_name : "");

              if (this.primary_customer) {
                if (ele.customer_id == this.primary_customer) {
                  ele.is_primary = "1";
                } else {
                  ele.is_primary = "0";
                }
              }
            });

            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    async getPackages() {
      this.showLoader("Loading packages");
      await this.$http
        .get(
          `venues/memberships/members/upgrade/get-all-packages/${this.newMembership.membership.id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.packages = data;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    async getPlans() {
      this.showLoader("Loading plans");
      await this.$http
        .get(
          `venues/memberships/members/upgrade/get-all-plans/${this.newMembership.package.id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.plans = data;
            this.hideLoader();
            if (this.plans && this.plans.length > 0) {
              this.newMembership.plan = this.plans[0].id;
            }
            if (this.newMembership.start_date) {
              this.setEndDate();
            }
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
  },
};
</script>
